import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";
import {AUTH_TOKEN_KEY} from "./authentication";
import {Storage} from "react-jhipster";

export type QROnboardState = Readonly<typeof qrOnboardInitialState>;

export const qrOnboardInitialState = {
    loading: false,
    isError: false,
    qrOnboard: {},
};


export const init = createAsyncThunk(
    'qr-onboard/init',
    async (id: string) => axios.get<any>(`/api/quick-onboarding/init/${id}`),
    {serializeError: serializeAxiosError}
);

export const finish = createAsyncThunk(
    'qr-onboard/finsih',
    async (id: string) => axios.get(`/api/quick-onboarding/finish/${id}`),
    {serializeError: serializeAxiosError}
);

export const qrOnboardSlice = createSlice({
        name: 'qr-onboard',
        initialState: qrOnboardInitialState as QROnboardState,
        reducers: {
            reset() {
                return qrOnboardInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(init.pending, (state) => {
                    state.loading = true;
                })
                .addCase(init.fulfilled, (state, action) => {
                    state.loading = false;
                    //@ts-ignore
                    const jwt = action.payload.headers.getAuthorization();
                    Storage.local.set(AUTH_TOKEN_KEY, jwt);
                    state.qrOnboard = action.payload.data;
                })
                .addCase(init.rejected, (state, action) => {
                    state.loading = false;
                    state.isError = true;
                })
                .addCase(finish.pending, (state) => {
                    state.loading = true;
                })
                .addCase(finish.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(finish.rejected, (state, action) => {
                    state.loading = false;
                    state.isError = true;
                })
            ;
        },
    })
;

export const {reset} = qrOnboardSlice.actions;

// Reducer
export default qrOnboardSlice.reducer;
