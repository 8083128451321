import React, {FC, useEffect, useRef, useState} from "react";
import {IPlan} from "../model/plan.model";
import {IPaymentIntent} from "./Checkout";
import {humanFileSize} from "../../shared/components/SimView";
import NcInputNumber from "../../shared/components/NcInputNumber/NcInputNumber";
import ESimSwitch from "../../shared/components/MySwitch/ESimSwitch";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import CountryFlags from "../CountryFlags";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import {listSubscriber} from "../reducers/subscriber.reducer";
import {ISubscriber} from "../model/subscriber.model";
import Amount from "../../shared/components/Amount";
import {useForm} from "react-hook-form";
import ESimSwitchWithLegal from "../../shared/components/MySwitch/ESimSwitchWithLegal";
import {translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {checkCoupon, reset} from "../reducers/coupon";
import {defaultCouponValue, ICoupon} from "../model/coupon.model";

interface IOrderInfo {
    plan: IPlan;
    callback?: (paymentIntent: IPaymentIntent, showPayment: boolean) => void;
}

const OrderInfo: FC<IOrderInfo> = ({plan, callback}) => {
    const dispatch = useAppDispatch();
    const initialized = useRef(false)
    const [quantity, setQuantity] = useState(1);
    const [isConsentAgreed, setIsConsentAgreed] = useState(false);
    const [isRecharge, setIsRecharge] = useState(false);
    const subscribers: ISubscriber[] = useAppSelector(state => state.subscriber.subscribers);
    const subscriberLoading: any[] = useAppSelector(state => state.subscriber.loading);
    const [selectedSubscriber, setSelectedSubscriber] = useState<string>('');
    const [voucherCode, setVoucherCode] = useState<string>('');
    const [selected, setSelected] = useState('');
    const {
        handleSubmit,
        trigger,
        formState,
        register,
        setError,
        clearErrors,
        formState: {errors}
    } = useForm({mode: 'onTouched'});
    const coupon: ICoupon = useAppSelector(state => state.coupon.coupon);
    const isCouponLoading: boolean = useAppSelector(state => state.coupon.loading);
    const isCouponError: boolean = useAppSelector(state => state.coupon.isError);

    useEffect(() => {
        if (!initialized.current) {
            // console.log('initial')
            initialized.current = true;
            dispatch(listSubscriber());
            dispatch(reset());
        }
        if (isCouponError) {
            setError('voucherCode', {message: translate('voucher.error.invalid')});
        } else {
            clearErrors('voucherCode');
        }
    }, [dispatch, isCouponError]);

    const acceptOrder = () => {
        // console.log("voucherCode", coupon);
        const paymentIntent: IPaymentIntent = {
            productId: plan.id,
            quantity: quantity,
            subscriber: selectedSubscriber,
            coupon: voucherCode
        };

        if (callback) {
            callback(paymentIntent, true);
        }

    }
    const filterSubscribers = () => {
        return subscribers.map(subscriber => {
            return {value: subscriber.imsiList[0].iccid, label: subscriber.imsiList[0].iccid}
        })
    };

    const applyVoucher = () => {
        setIsConsentAgreed(false);
        dispatch(checkCoupon(voucherCode));
    }

    const removeCoupon = () => {
        dispatch(reset())
        setVoucherCode('')
    };

    const getDiscountValue = () => {
        if (coupon.percentOff && coupon.percentOff > 0) {
            return coupon.percentOff + "% off";
        }
        if (coupon.amountOff && coupon.amountOff > 0) {
            return coupon.amountOff + " off";
        }
        return '';
    }

    // @ts-ignore
    return (
        <div className={"pt-6"}>
            <div id="OrderInfo"
                 className={'px-6 py-6 rounded-3xl border-2 flex flex-col overflow-hidden border-neutral-100 dark:border-neutral-700 bg-neutral-100'}>
                <div className="grid lg:grid-cols-1 gap-1 xl:gap-1">

                    <label className="block relative px-3 py-4">
                        <span className={"font-medium"}>{translate("order.price")}</span>
                        <span className="la-pull-right">
                                              <Amount amount={plan.price} currency={plan.currency || ''}
                                                      vatIncluded={false}/>
                                            </span>
                    </label>
                    <label className="block relative px-3 py-4">
                        <span className={"font-medium"}>{translate("order.data")}</span>
                        <span className="la-pull-right">
                                                {

                                                    humanFileSize(plan.data)
                                                }
                                            </span>
                    </label>
                    <label className="block relative px-3 py-4">
                        <span className={"font-medium"}>{translate("order.validity")}</span>
                        <span className="la-pull-right">
                                                {

                                                    plan.validityDays + " " + translate("global.text.days")
                                                }
                                            </span>
                    </label>
                    <label className="block relative px-3 py-4">
                        <span className={"font-medium"}>{translate("global.text.coverage")}</span>
                        <span className="la-pull-right">
<CountryFlags zones={plan.operators}></CountryFlags>
                                            </span>
                    </label>

                    <label className="block relative px-3 py-4">
                        <span className={"font-medium"}>{translate("order.description")}</span>
                        <span className="la-pull-right w-4/4">
                                                {

                                                    plan.description
                                                }
                        </span>
                    </label>


                    {/*<label className="grid-cols-4 grid xsm:relative relative px-3 py-4">*/}
                    {/*    <span className={"col-span-1  font-medium"}>Description</span>*/}
                    {/*    <span className=" col-span-3  text-justify">*/}
                    {/*                            {*/}
                    {/*                                */}
                    {/*                                plan.description*/}
                    {/*                            }*/}
                    {/*                        </span>*/}
                    {/*</label>*/}
                </div>
            </div>
            <hr className="mt-12 mb-2 border-slate-200 dark:border-slate-700"/>

            <label className="block relative px-3 py-4">
                <span className={"font-medium"}>{translate("order.quantity")}</span>
                <span className="la-pull-right">
                                    <NcInputNumber min={1} max={5} onChange={value => {
                                        setQuantity(value);
                                        if (value !== 1) {
                                            setSelectedSubscriber('');
                                        }
                                    }}></NcInputNumber>
                                </span>
            </label>
            <hr className="mt-2 mb-2 border-slate-200 dark:border-slate-700"/>
            <label className={`block relative px-3 py-4`}>
                <span className={"font-medium"}>{translate("order.total")}</span>
                <span className="la-pull-right">
                   <Amount amount={quantity * plan.price} currency={plan.currency || ''} vatIncluded={false}/>
                    </span>
            </label>
            <hr className="mt-2 mb-2 border-slate-200 dark:border-slate-700"/>
            <label className={`block relative px-3 ${isCouponError ? 'py-12' : 'py-4'} `}>
                <span className={"font-medium"}>{translate('voucher.discount-code')}</span>
                <span className="la-pull-right flex justify-between mt-auto">
                    <div>
                    <div className="flex no-margin-validation-field">
                         <ValidatedForm className={'flex no-margin-validation-field'} onSubmit={applyVoucher}>
                        <ValidatedField
                            //@ts-ignore
                            error={errors?.voucherCode}
                            className={''}
                            disabled={isConsentAgreed || coupon !== defaultCouponValue}
                            name="voucherCode"
                            type={'text'}
                            value={voucherCode}
                            onChange={(v) => {
                                if (isCouponError) {
                                    // clearErrors('voucherCode');
                                }
                                setVoucherCode(v.target.value);
                            }}
                            inputClass={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white placeholder-gray-300 dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-10 px-4 py-3 flex-1 placeholder-gray-300 ${coupon !== defaultCouponValue ? `disabled bg-opacity-50` : ``}`}
                            placeholder={translate('voucher.code-placeholder')}
                        />
                        <button
                            className={`bg-slate-900 dark:bg-slate-100 hover:bg-slate-900 w-100 text-slate-50 border border-neutral-200 dark:border-neutral-700 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors ${voucherCode.length === 0 ? `disabled bg-opacity-50` : ``} ${isConsentAgreed ? `disabled bg-opacity-50` : ``} ${isCouponLoading ? `disabled bg-opacity-50` : ``} ${coupon !== defaultCouponValue ? `disabled bg-opacity-50` : ``}`}
                        >
                            {translate('voucher.button-text')}
                        </button>
                             </ValidatedForm>
                    </div>
                </div>
                    </span>

                {coupon !== defaultCouponValue ?
                    <div className="block relative px-3 py-6">
                        <div>
                            <div className="flex justify-between ">
                                <div className="flex-1">
                                    <h3 className="text-base font-semibold relative z-10 flex items-center mt-3">
                                        {coupon.name} {getDiscountValue()}
                                    </h3>
                                </div>
                                <div className="flex-1 sm:flex justify-end">
                                    <div className="flex mt-auto items-end justify-between text-sm">
                                        <div className="hidden sm:block text-center relative">
                                        </div>
                                        <a
                                            onClick={removeCoupon}
                                            className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm cursor-pointer"
                                        >
                                            <span>{translate("global.text.remove")}</span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

            </label>
            <hr className={`mt-2 mb-2 border-slate-200 dark:border-slate-700 ${(quantity === 1 && subscribers.length > 0) ? '' : 'hidden'}`}/>
            <div className={`relative px-3 py-4 ${(quantity === 1 && subscribers.length > 0) ? '' : 'hidden'}`}>
                <ESimSwitch
                    label={translate("order.recharge-label")}
                    desc={translate("order.recharge-description")}
                    onChange={(v) => {
                        setIsRecharge(v);
                        if (!v) {
                            setSelectedSubscriber('');
                        }
                    }}
                />
            </div>
            <div
                className={`relative px-2 pb-4 pt-0 ${(quantity === 1 && subscribers.length > 0 && isRecharge) ? '' : 'hidden'}`}>
                <select
                        // placeholder={translate("order.subscriber-placeholder")}
                        // defaultValue={selectedSubscriber}
                        value={selectedSubscriber}
                        onChange={(v) => {
                            if (v) {
                                setSelectedSubscriber(v.target.value)
                            } else {
                                setSelectedSubscriber('');
                            }
                        }
                        }
                        className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300 form-select ${selectedSubscriber !== '' ? 'is-valid' : 'is-invalid'}`}
                >
                    <option></option>
                    {subscribers.map(subscriber => {
                        return <option key={subscriber.imsiList[0].iccid}
                            value={subscriber.imsiList[0].iccid}>{subscriber.company ? subscriber.company : subscriber.imsiList[0].iccid}</option>
                    })}
                </select>
                <div
                    className={`invalid-feedback ${selectedSubscriber === '' ? '' : 'hidden'}`}>{translate("order.error.subscriber-required")}
                </div>
            </div>


            <hr className="mt-2 mb-2 border-slate-200 dark:border-slate-700"/>

            <div className="relative px-3 py-4">
                <ESimSwitchWithLegal
                    onChange={(v => {
                        setIsConsentAgreed(v);
                        // @ts-ignore
                        if (voucherCode.length > 0 && coupon === defaultCouponValue) {
                            setVoucherCode('');
                        }
                    })}
                />
            </div>
            <hr className="mt-2 border-slate-200 dark:border-slate-700"/>

            <div className=" py-12 lg:py-12 flex flex-col sm:flex-row  grid lg:grid-cols-4 md:grid-cols-4">
                <ButtonPrimary className={"bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 w-100 text-white"}
                               type="submit"
                               onClick={acceptOrder}
                               disabled={isRecharge ? (selectedSubscriber === '' || !isConsentAgreed) : !isConsentAgreed}>
                    {translate("order.submit-button")}</ButtonPrimary>
            </div>


        </div>


    )
}


export default OrderInfo;
