import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";

export type OrderState = Readonly<typeof orderInitialState>;

export const orderInitialState = {
    loading: false,
    orders: [] as any[],
};


export const listOrders = createAsyncThunk(
    'order/list',
    async => axios.get<any[]>(`/api/user/order`,
        {
            headers: {
                'Cache-Control': 'no-cache'
            }
        }
    ),
    {serializeError: serializeAxiosError}
);

export const OrderSlice = createSlice({
        name: 'order',
        initialState: orderInitialState as OrderState,
        reducers: {
            reset() {
                return orderInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(listOrders.pending, (state) => {
                    state.loading = true;
                })
                .addCase(listOrders.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orders = action.payload.data;
                })
                .addCase(listOrders.rejected, (state, action) => {
                    state.loading = false;
                })
            ;
        },
    })
;

export const {reset} = OrderSlice.actions;

// Reducer
export default OrderSlice.reducer;
