"use client";

import React, {FC, useEffect, useState} from "react";
import {Transition} from "@headlessui/react";
import iOSStep3 from "../../../shared/img/ios-step-3.png";
import iOSStep5 from "../../../shared/img/ios-step-5.png";
import iOSStep6 from "../../../shared/img/ios-step-6.png";
import androidStep7 from "../../../shared/img/android-step-7.png";
import NavItem from "../../../shared/components/NavItem/NavItem";
import Nav from "../../../shared/components/Nav/Nav";
import ESIMImg from "../../../shared/components/eSIMImg";
import {useAppState} from "../../config/AppStateContext";
import ReactPlayer from 'react-player'
import {translate} from "react-jhipster";
import {useAppSelector} from "../../config/localStorage";


export interface FAQOSSelectorProps {
    className?: string;
}

const FAQOSSelector: FC<FAQOSSelectorProps> = ({
                                                   className = "aaa",
                                               }) => {
    const [isOpen] = useState(true);
    const [tabActive, setTabActive] = useState(0);
    const {state} = useAppState();
    const currentLocale = useAppSelector(state => state.locale.currentLocale);
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    useEffect(() => {
        if (currentLocale) {
            setIsPlayerReady(true);
        }
    }, [currentLocale]);

    return (
        <div className={`flex flex-col relative ${className}`}>
            <div
                className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
                <Nav
                    className="sm:space-x-2"
                    containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
                >
                    {["🍏 " + translate("global.text.ios"), "🤖 " + translate("global.text.android")].map(
                        (item, index) => (
                            <NavItem
                                key={index}
                                isActive={tabActive === index}
                                onClick={() => setTabActive(index)}
                            >
                                {item}
                            </NavItem>
                        )
                    )}
                </Nav>
            </div>

            <Transition
                show={isOpen}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                {/* Conditional rendering based on the selected tab */}
                {tabActive === 0 ? (
                    <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8">

                        <div className="listingSectionSidebar__wrap lg:shadow-lg">
                            <div className="space-y-7 lg:space-y-8">
                                <div>
                                    {/* ---------- 1 HEADING ----------  */}
                                    <div className="flex items-center justify-between space-x-5">
                                        <div className="flex text-2xl font-semibold">
                                            {translate("first-use.ios-video")}
                                        </div>


                                    </div>
                                    <div className=" sm:flex flex-col space-y-4 ">
                                        <div className="space-y-2.5">
                                            <div
                                                className="flex justify-between text-slate-600 dark:text-slate-300">
                                                <div className="w-full">

                                                    <div
                                                        className=" mx-0 mx-sm-12 mt-6 mr-auto ml-auto content-center rounded-2xl sm:w-8/12 w-11/12"
                                                    >

                                                        {currentLocale && isPlayerReady ? (
                                                            <ReactPlayer
                                                                controls={true}
                                                                light={false}
                                                                url={`/cdn/content/ios_${currentLocale}.mp4`}
                                                                width="100%"
                                                                height="100%"
                                                                style={{borderRadius: '1rem'}}
                                                            />
                                                        ) : null}


                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                ) : tabActive === 1 ? (
                    <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-8">

                        <div className="listingSectionSidebar__wrap lg:shadow-lg">
                            <div className="space-y-7 lg:space-y-8">
                                <div>
                                    {/* ---------- 1 HEADING ----------  */}
                                    <div className="flex items-center justify-between space-x-5">
                                        <div className="flex text-2xl font-semibold">
                                            {translate("first-use.android-video")}
                                        </div>


                                    </div>
                                    <  div className=" sm:flex flex-col space-y-4 ">
                                        <div className="space-y-2.5">
                                            <div
                                                className="flex justify-between text-slate-600 dark:text-slate-300">
                                                <div className="w-full">

                                                    <div
                                                        className=" mx-0 mx-sm-12 mt-6 mr-auto ml-auto content-center rounded-2xl sm:w-8/12 w-11/12"
                                                    >
                                                        {currentLocale && isPlayerReady ? (
                                                            <ReactPlayer
                                                                controls={true}
                                                                light={false}
                                                                url={`/cdn/content/android_${currentLocale}.mp4`}
                                                                width="100%"
                                                                height="100%"
                                                                style={{borderRadius: '1rem'}}
                                                            />
                                                        ) : null}

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                ) : null}
            </Transition>
        </div>
    );
};

export default FAQOSSelector;
