export function formatDateWithMinute(date: Date) {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${month}/${day}/${year} ${hours}:${minutes}`;
}

export function formatDate(date: Date) {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const day = date.getDate();
    return `${month}/${day}/${year}`;
}

export const keyGenerator = () => {
    return Math.random().toString(36).slice(2,9)
}