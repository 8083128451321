import React, {FC} from "react";
import facebook from "../../img/socials/facebook.svg";
import twitter from "../../img/socials/twitter.svg";
import instagram from "../../img/socials/instagram.svg";
import linkedin from "../../img/socials/linkedin.svg";
import youtube from "../../img/socials/youtube.svg";
import tiktok from "../../img/socials/tiktok.svg";
import {useAppState} from "../../../app/config/AppStateContext";
import GoogleAnalytics from "../../../GoogleAnalytics";

export interface SocialsListProps {
    className?: string;
    itemClass?: string;
}

const SocialsList: FC<SocialsListProps> = ({

                                               className = "",
                                               itemClass = "block w-6 h-6",
                                           }) => {
    // const { state } = useAppState();
    const {state} = useAppState();

    const socialsDemo: any[] = [
        {name: "Facebook", icon: facebook, href: state.tenant ? state.tenant.socialMediaLinks.facebook : ''},
        {name: "Twitter", icon: twitter, href: state.tenant ? state.tenant.socialMediaLinks.twitter : ''},
        {name: "Instagram", icon: instagram, href: state.tenant ? state.tenant.socialMediaLinks.instagram : ''},
        {name: "LinkedIn", icon: linkedin, href: state.tenant ? state.tenant.socialMediaLinks.linkedIn : ''},
        {name: "YouTube", icon: youtube, href: state.tenant ? state.tenant.socialMediaLinks.youtube : ''},
        {name: "TikTok", icon: tiktok, href: state.tenant ? state.tenant.socialMediaLinks.tiktok : ''},
    ];
    return (

        <nav
            className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
        >
            {socialsDemo.filter(item => item.href).map((item, i) => (
                <a
                    key={i}
                    className={`${itemClass}`}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={item.name}
                >
                    <img sizes="40px" src={item.icon} alt=""/>
                </a>
            ))}
        </nav>
    );
};

export default SocialsList;
