import React, {useEffect} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {tokenLogin} from "./reducers/authentication";
import {translate} from "react-jhipster";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";

const QuickOrderSuccess: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.authentication); // Select authentication state

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate("/account-plans");
        }
    }, [auth.isAuthenticated, navigate]);

    return (
        <div className="container">
        <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                <div
                    className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                    <div className={`w-full`}>
                        <div
                            className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                            <div className="relative">

                                <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                    {/*{translate("quick-order-success.title")}*/}
                                    Purchase Successful
                                </h2>
                                <span className="block mt-6 text-slate-500 dark:text-slate-400">
                                        {/*{translate("quick-order-success.description")}*/}
                                    Your purchase was successful, and your eSIM is on its way. Please check your inbox for your unique authentication email. You can always use the link in the email to access your account.
                                        </span>
                                <div className="flex space-x-2 mt-6 mb-6">
                                    <ButtonPrimary
                                        href="/"
                                        className="buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                    >
                                        {translate("global.destination-button")}
                                    </ButtonPrimary>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
    );
};

export default QuickOrderSuccess;
