import {ICountry} from "../model/country";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";

export type CountryState = Readonly<typeof countryInitialState>;

export const countryInitialState = {
    loading: false,
    countries: [] as ICountry[],
};

export const findCountry = createAsyncThunk(
    'country/find',
    async (name: string) => axios.get<ICountry[]>(`/api/country?country=${name}`),
    {serializeError: serializeAxiosError}
);

export const CountrySlice = createSlice({
        name: 'country',
        initialState: countryInitialState as CountryState,
        reducers: {
            reset() {
                return countryInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(findCountry.pending, (state) => {
                    state.loading = true;
                })
                .addCase(findCountry.fulfilled, (state, action) => {
                    state.loading = false;
                    state.countries = action.payload.data;
                })
                .addCase(findCountry.rejected, (state, action) => {
                    state.loading = false;
                })
            ;
        },
    })
;

export const {reset} = CountrySlice.actions;

// Reducer
export default CountrySlice.reducer;