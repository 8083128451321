"use client";

import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {GlobeAltIcon} from "@heroicons/react/24/outline";
import React, {Fragment} from "react";
import {Popover, Tab, Transition} from "../../headlessui";
import {translate} from "react-jhipster";
import {useAppState} from "../../app/config/AppStateContext";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const LocaleMenu = ({currentLocale, onClick}: { currentLocale: string; onClick: (event: string) => void }) => {
    const {state} = useAppState();
    const renderLang = (close: () => void) => {
        return (
            <div className="grid gap-8 lg:grid-cols-2">
                {state.tenant.i18n.languages.map((locale: string, index: number) => (
                    <a
                        key={locale}
                        // @ts-ignore
                        onClick={() => {
                            onClick(locale);
                            close()
                        }}
                        className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                            currentLocale === locale ? "bg-gray-100 dark:bg-gray-700" : "opacity-80"
                        }`}
                    >
                        <div className="">
                            <p className="text-sm font-medium ">{translate(`global.lang.${locale}`)}</p>

                        </div>
                    </a>
                ))}
            </div>
        );
    };

    return (
        <>
            {state.tenant.i18n.languages.length > 1 ? <div className="LangDropdown  md:block ">
                <Popover className="relative">
                    {({open, close}) => (
                        <>
                            <Popover.Button
                                className={`
                ${open ? "" : "text-opacity-80"}
             group h-10 sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-gray-800 dark:text-neutral-200 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                                <GlobeAltIcon className="w-[18px] h-[18px] opacity-80"/>
                                {/*<span className="ml-2">{translate("global.lang."+currentLocale)}</span>*/}
                                <ChevronDownIcon
                                    className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-1 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel
                                    className={`absolute z-20 lg:w-96 sm:w-auto md:w-auto  mt-3.5 right-0`}
                                >
                                    <div
                                        className="p-6 rounded-2xl bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5">
                                        <Tab.Group>
                                            {/*<Tab.List*/}
                                            {/*    className="flex space-x-1 rounded-full bg-gray-100 dark:bg-slate-700 p-1">*/}
                                            {/*    {["Language", "Currency"].map((category) => (*/}
                                            {/*        <Tab*/}
                                            {/*            key={category}*/}
                                            {/*            className={({selected}) =>*/}
                                            {/*                classNames(*/}
                                            {/*                    "w-full rounded-full py-2 text-sm font-medium leading-5 text-gray-700",*/}
                                            {/*                    "focus:outline-none focus:ring-0",*/}
                                            {/*                    selected*/}
                                            {/*                        ? "bg-white shadow"*/}
                                            {/*                        : "text-gray-700 dark:text-slate-300 hover:bg-white/70 dark:hover:bg-slate-900/40"*/}
                                            {/*                )*/}
                                            {/*            }*/}
                                            {/*        >*/}
                                            {/*            {category}*/}
                                            {/*        </Tab>*/}
                                            {/*    ))}*/}
                                            {/*</Tab.List>*/}
                                            <Tab.Panels>
                                                <Tab.Panel
                                                    className={classNames(
                                                        "rounded-xl p-3 cursor-pointer",
                                                        "focus:outline-none focus:ring-0"
                                                    )}
                                                >
                                                    {renderLang(close)}
                                                </Tab.Panel>
                                                <Tab.Panel
                                                    className={classNames(
                                                        "rounded-xl p-3 cursor-pointer",
                                                        "focus:outline-none focus:ring-0"
                                                    )}
                                                >
                                                </Tab.Panel>
                                            </Tab.Panels>
                                        </Tab.Group>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </div> : null}
        </>
    );
};
export default LocaleMenu;
