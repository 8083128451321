import {IOperator} from "./operator.model";

export interface IPlan {
    id: string;
    name: string;
    label?: string;
    description: string;
    validityDays?: number;
    data: number;
    price: number;
    currency?: string;
    operators: IOperator[];
    backgroundColorHex: string;
    backgroundImageUrl: string;
    colorHex?: string;
    icon?: string;
    locationZoneId: number;
    hideDestinations: boolean;
}

export const defaultPlanValue: Readonly<IPlan> = {
    id: '',
    name: '',
    label: '',
    description: '',
    validityDays: 0,
    data: 0,
    price: 0,
    currency: '',
    operators: [],
    backgroundColorHex: "",
    backgroundImageUrl: "",
    colorHex: '',
    icon: '',
    locationZoneId: 0,
    hideDestinations: false,
};
