import React, {FC, useEffect, useRef, useState} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {IPaymentIntent} from "./Checkout";
import {useAppState} from "../config/AppStateContext";
import axios from "axios";
import {useLocation} from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import {useNavigate} from "react-router";
import {useAppSelector} from "../config/localStorage";

interface IPaymentMethod {
    iPaymentIntent: IPaymentIntent;
}

const V2PaymentMethod: FC<IPaymentMethod> = ({iPaymentIntent}) => {
    const initialized = useRef(false)
    const {state} = useAppState();
    const location = useLocation();
    const [sessionId, setSessionId] = useState(null);
    const navigate = useNavigate();
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);


    useEffect(() => {
        var paymentUrl = "/api/v1/payment"
        if(!isAuthenticated){
            paymentUrl += "/session-less";
        }
        if (!initialized.current) {
            initialized.current = true;
            iPaymentIntent.cancelEndpoint = location.pathname;
            loadStripe(state.tenant.stripePublishableKey).then((stripe) => {
                axios.post(paymentUrl, iPaymentIntent)
                    .then(r => {
                        console.log(r);
                        if(r.data.redirect){
                            navigate(r.data.redirect, {state: {from: location.pathname}});
                            return;
                        }
                        stripe?.redirectToCheckout({sessionId: r.data.sessionId})
                    })

            })
        }
    }, [state.tenant.stripePublishableKey, isAuthenticated])

    const renderPaymentMethod = () => {
        return (
            <div id="PaymentMethod"
                 className={'container'}>
                <div>

                    <div className="">
                        <LoadingSpinner/>

                    </div>
                </div>
            </div>
        );
    };

    return renderPaymentMethod();
};

export default V2PaymentMethod;
