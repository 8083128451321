import React, {FC, useEffect, useRef, useState} from "react";
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe, Stripe, StripeError} from '@stripe/stripe-js';
import {IPaymentIntent} from "./Checkout";
import {useAppState} from "../config/AppStateContext";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import axios from "axios";
import {Alert} from "../../shared/components/Alert/Alert";
import {useLocation} from "react-router-dom";
import Amount from "../../shared/components/Amount";
import LoadingSpinner from "../LoadingSpinner";
import toast, {Toast} from "react-hot-toast";
import {Transition} from "../../headlessui";
import {useNavigate} from "react-router";
import {translate} from "react-jhipster";

interface IPaymentMethod {
    iPaymentIntent: IPaymentIntent;
    callback: () => void;
}

const PaymentMethod: FC<IPaymentMethod> = ({iPaymentIntent, callback}) => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
    const [stripeError, setStripeError] = useState(undefined as StripeError | undefined);
    const initialized = useRef(false)
    const {state} = useAppState();
    const location = useLocation();
    const navigate = useNavigate();
    const [transactionData, setTransactionData] = useState<any>(null);

    const createTransactionSecret = () => {
        axios.post("/api/checkout/integrated", iPaymentIntent)
            .then(r => {
                setTransactionData(r.data)
                if (r.data.clientSecret === null) {
                    navigate(state.tenant.stripeReturnUrl)
                }
            }).catch(e => {
            callback();
            toast.custom(
                (t: Toast) => (
                    <Transition
                        appear
                        show={t.visible}
                        className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                        enter="transition-all duration-150"
                        enterFrom="opacity-0 translate-x-20"
                        enterTo="opacity-100 translate-x-0"
                        leave="transition-all duration-150"
                        leaveFrom="opacity-100 translate-x-0"
                        leaveTo="opacity-0 translate-x-20"
                    >
                        <p className="block" style={{color: state.tenant ? state.tenant.red : "black"}}>
                            Invalid payment request. Please try again later.
                        </p>
                    </Transition>
                ),
                {
                    position: "top-right",
                    duration: 5000,
                }
            );
            console.log(e)
        });
    }

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            setStripePromise(loadStripe(state.tenant.stripePublishableKey));
            if (transactionData === null) {
                createTransactionSecret();
            }
        }
        loadStripe(state.tenant.stripePublishableKey)
    }, [state.tenant.stripePublishableKey, transactionData])


    const isPaymentReady = (ready: boolean) => {
    }


    const cancelPaymnet = () => {
        //@ts-ignore
        callback();
    }

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const [disablePayment, setDisablePayment] = useState(false);
        const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            setDisablePayment(true);
            if (!stripe || !elements) {
                return;
            }
            const result = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: window.location.origin + state.tenant.stripeReturnUrl,
                },
            });

            if (result.error) {
                setDisablePayment(false);
                console.log(result.error);
                if (result.error) setStripeError(result.error);
            }
        };

        return <>
            <div className="flex flex-col lg:flex-row">
                <div className="flex-1">
                    <h3 className="text-lg   font-semibold">{translate("order.checkout.order-summary")}</h3>
                    <div
                        className="mt-6 text-sm text-slate-500 dark:text-slate-400 border-slate-200/70 dark:border-slate-700 ">
                        {/*<div>*/}
                        {/*    <Label className="text-sm">Discount code</Label>*/}
                        {/*    <div className="flex mt-1.5">*/}
                        {/*        <Input sizeClass="h-10 px-4 py-3" className="flex-1"/>*/}
                        {/*        <button*/}
                        {/*            className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">*/}
                        {/*            Apply*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="mt-0 flex justify-between py-2.5">
                            <span>{translate("order.checkout.subtotal")}</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200">
                                <Amount amount={transactionData.amount} currency={transactionData.currency}
                                        vatIncluded={true}/>
                </span>
                        </div>
                        <div className="flex justify-between py-2.5">
                            <span>{translate("order.checkout.total-without-tax")}</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200">
                  <Amount amount={transactionData.amount} currency={transactionData.currency} vatIncluded={true}/>
                </span>
                        </div>
                        <div className="flex justify-between py-2.5">
                            <span>{translate("global.text.vat")} ({transactionData.taxPercentage}% on <Amount
                                amount={transactionData.amount}
                                currency={transactionData.currency}
                                vatIncluded={true}/>)</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200">
                  <Amount amount={transactionData.amountTax} currency={transactionData.currency} vatIncluded={true}/>
                </span>
                        </div>
                        <div
                            className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                            <span>{translate("order.checkout.total")}</span>
                            <span><Amount amount={transactionData.amountTotal} currency={transactionData.currency}
                                          vatIncluded={true}/></span>
                        </div>
                    </div>
                    {/*<ButtonPrimary className="mt-8 w-full">Confirm order</ButtonPrimary>*/}
                    {/*    <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">*/}
                    {/*        <p className="block relative pl-5">*/}
                    {/*            <svg*/}
                    {/*                className="w-4 h-4 absolute -left-1 top-0.5"*/}
                    {/*                viewBox="0 0 24 24"*/}
                    {/*                fill="none"*/}
                    {/*            >*/}
                    {/*                <path*/}
                    {/*                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"*/}
                    {/*                    stroke="currentColor"*/}
                    {/*                    strokeWidth="1.5"*/}
                    {/*                    strokeLinecap="round"*/}
                    {/*                    strokeLinejoin="round"*/}
                    {/*                />*/}
                    {/*                <path*/}
                    {/*                    d="M12 8V13"*/}
                    {/*                    stroke="currentColor"*/}
                    {/*                    strokeWidth="1.5"*/}
                    {/*                    strokeLinecap="round"*/}
                    {/*                    strokeLinejoin="round"*/}
                    {/*                />*/}
                    {/*                <path*/}
                    {/*                    d="M11.9945 16H12.0035"*/}
                    {/*                    stroke="currentColor"*/}
                    {/*                    strokeWidth="2"*/}
                    {/*                    strokeLinecap="round"*/}
                    {/*                    strokeLinejoin="round"*/}
                    {/*                />*/}
                    {/*            </svg>*/}
                    {/*            Learn more{` `}*/}
                    {/*            <a*/}
                    {/*                target="_blank"*/}
                    {/*                rel="noopener noreferrer"*/}
                    {/*                href="##"*/}
                    {/*                className="text-slate-900 dark:text-slate-200 underline font-medium"*/}
                    {/*            >*/}
                    {/*                Taxes*/}
                    {/*            </a>*/}
                    {/*            <span>*/}
                    {/*  {` `}and{` `}*/}
                    {/*</span>*/}
                    {/*            <a*/}
                    {/*                target="_blank"*/}
                    {/*                rel="noopener noreferrer"*/}
                    {/*                href="##"*/}
                    {/*                className="text-slate-900 dark:text-slate-200 underline font-medium"*/}
                    {/*            >*/}
                    {/*                Shipping*/}
                    {/*            </a>*/}
                    {/*            {` `} infomation*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                </div>

                <div
                    className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>


                <div className="flex-1">
                    {/*<VStack>*/}
                    {stripeError === undefined ? <></> : <Alert type={'error'}
                                                                containerClassName={'w-full inline-flex items-center justify-center font-medium'}>{stripeError.message}</Alert>}
                    <PaymentElement
                        //@ts-ignore
                        onReady={isPaymentReady}
                        //@ts-ignore
                        onLoaderStart={isPaymentReady}
                    />
                    <div className="flex flex-col sm:flex-row pt-6 grid lg:grid-cols-2">
                        <div>
                            <ButtonPrimary
                                className="buyCardButton w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                disabled={disablePayment}
                                //@ts-ignore
                                onClick={handleSubmit}
                            >
                                {translate("order.checkout.pay")} <Amount amount={transactionData.amountTotal}
                                                                          currency={transactionData.currency}
                                                                          vatIncluded={true}/>
                            </ButtonPrimary>
                        </div>
                        <div className={'mb-12'}>
                            <ButtonSecondary
                                className="mt-3 sm:mt-0 sm:ml-3 w-full bg-white text-slate-700 dark:bg-slate-900 dark:text-slate-300 hover:bg-gray-100 dark:hover:bg-slate-800 border border-slate-100 dark:border-slate-700 "
                                disabled={disablePayment}
                                onClick={cancelPaymnet}
                            >
                                {translate("order.checkout.cancel")}
                            </ButtonSecondary>
                        </div>
                    </div>
                    {/*</VStack>*/}
                </div>
            </div>

        </>
    }

    const renderPaymentMethod = () => {
        return (
            <div id="PaymentMethod"
                 className={'container'}>
                <div>

                    <div className="">
                        {(transactionData === null ?
                                <LoadingSpinner/>
                                :
                                <Elements stripe={stripePromise} options={{clientSecret: transactionData.clientSecret}}>
                                    <CheckoutForm/>
                                </Elements>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return renderPaymentMethod();
};

export default PaymentMethod;
