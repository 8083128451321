import React, {FC, useEffect, useState} from "react";
import Label from "../Label/Label";
import {useAppState} from "../../../app/config/AppStateContext";
import {isEmail, translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {IUser} from "../../../app/model/user.model";
import {useAppSelector} from "../../../app/config/localStorage";

export interface MySwitchProps {
    enabled?: boolean;
    label?: string;
    desc?: string;
    className?: string;
    onChange: (email: string) => void;
    disabled?: boolean;
}

const GuestEmail: FC<MySwitchProps> = ({
                                           enabled = false,

                                           className = "",
                                           onChange,
                                           disabled = false,
                                       }) => {
    const {state} = useAppState();
    const [enabledState, setEnabledState] = useState(false);
    const [guestEmail, setGuestEmail] = useState("");
    const account: IUser = useAppSelector(state => state.authentication.account);
    const emailFormEnabled = useAppSelector(state => !!state.authentication.account.email);

    useEffect(() => {
        setEnabledState(enabled);
        console.log("account", account.email);

        if (account.email) {
            setGuestEmail(account.email);
            onChange(account.email);
        }

    }, [enabled, disabled]);

    useEffect(() => {
        if(account && account.email) {
            setGuestEmail(account.email);
            onChange(account.email);
        }
    }, [account]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setGuestEmail(email);
        onChange(email);
    };

    return (
        <div className={`MySwitch flex justify-between items-center flex-col sm:flex-row sm:items-center sm:space-x-2 ${className}`}>
            <div>
                <Label>
                    {emailFormEnabled ? "You are using quick checkout" : "You are checking out as a guest"}
                </Label>
                <p className="text-neutral-500 dark:text-neutral-400 text-xs w-1/2">
                    {/*Your order email will contain a unique link to login and manage your eSIMs.*/}
                    {/*If you have an account,*/}
                    {/*please click here to login.*/}

                    {emailFormEnabled ? (
                        <span>
        If you wish to personalize your invoice, please update your billing details in <a className={"underline"} href='/account'>account</a>.
    </span>
                    ) : (
                        "Your order email will contain a unique link to login and manage your eSIMs."
                    )}
                </p>
            </div>
            <div className="flex flex-col w-full text-right ">
                <ValidatedForm mode={"onChange"} className="w-full sm:w-1/2"
                    //@ts-ignore
                               onSubmit={console.log("submit")}>
                    <ValidatedField
                        name="email"
                        labelHidden={true}
                        disabled={emailFormEnabled}
                        defaultValue={account?.email || ""}
                        placeholder={translate("global.email-placeholder")}
                        type="email"
                        inputClass={'w-full block border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 py-3 mt-1 placeholder-gray-300'}
                        validate={{
                            required: {value: true, message: translate("global.error.email-required")},
                            minLength: {value: 5, message: translate("global.error.email-length-min")},
                            maxLength: {value: 50, message: translate("global.error.email-length-max")},
                            validate: v => isEmail(v) || translate("global.error.email-invalid"),
                        }}
                        data-cy="emailResetPassword"
                        onChange={handleEmailChange}
                    />
                </ValidatedForm>
            </div>

        </div>
    );
};

export default GuestEmail;
