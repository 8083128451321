import React, {useState} from 'react';
import {useAppState} from "../../../app/config/AppStateContext";
import ButtonSecondary from "./ButtonSecondary";

const AddButton: React.FC = () => {
    const [showOptions, setShowOptions] = useState(false);
    const {state} = useAppState();

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };
    return (
        <div className="fixed bottom-0 left-0 pl-2 pb-4 z-30">
            <div className="relative">
                <button
                    style={{backgroundColor: state.tenant.primaryColor}}

                    className={`text-white p-2 rounded-full shadow-lg hover:bg-[#FF4500] focus:outline-none`}
                    onClick={toggleOptions}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-11 w-11"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                    </svg>
                </button>

                {showOptions && (
                    <div className="absolute left-0 bottom-14 bg-white p-2 rounded-lg shadow-lg">
                        <ButtonSecondary href="/account-voucher" className="">
                            Voucher
                        </ButtonSecondary>
                        <ButtonSecondary href="/" className="">
                            Destinations
                        </ButtonSecondary>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddButton;
