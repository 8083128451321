import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";

export type PopularCountriesState = Readonly<typeof popularCountriesInitialState>;

export const popularCountriesInitialState = {
    loading: false,
    updateLoading: false,
    popularCountries: [] as string[],
};


export const getPopularCountries = createAsyncThunk(
    'popular-countries/get',
    async () => axios.get<string[]>(`/api/popular-countries`),
    {serializeError: serializeAxiosError}
);

export const updatePopularCountries = createAsyncThunk(
    'popular-countries/update',
    async (country: string, thunkAPI) => {
        return axios.post(`/api/popular-countries/${country}`)
    },
    {serializeError: serializeAxiosError}
);

export const languageSlice = createSlice({
        name: 'popular-countries',
        initialState: popularCountriesInitialState as PopularCountriesState,
        reducers: {
            reset() {
                return popularCountriesInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(getPopularCountries.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getPopularCountries.fulfilled, (state, action) => {
                    state.loading = false;
                    state.popularCountries = action.payload.data;
                })
                .addCase(getPopularCountries.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(updatePopularCountries.pending, (state) => {
                    state.updateLoading = true;
                })
                .addCase(updatePopularCountries.fulfilled, (state, action) => {
                    state.updateLoading = false;
                })
                .addCase(updatePopularCountries.rejected, (state, action) => {
                    state.updateLoading = false;
                })
        },
    })
;

export const {reset} = languageSlice.actions;

// Reducer
export default languageSlice.reducer;
