import React, {useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import {activateAction} from "../reducers/account/activate.reducer";
import {IUser} from "../model/user.model";
import {useLocation} from "react-router-dom";
import {Alert} from "../../shared/components/Alert/Alert";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import {translate} from "react-jhipster";

const ActivateAccountPage = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const initialized = useRef(false)
    const activationSuccess: IUser = useAppSelector(state => state.activate.activationSuccess);
    const activationFailure: IUser = useAppSelector(state => state.activate.activationFailure);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            let key = location.search.split('?key=')[1];
            dispatch(activateAction(key as string));
        }
    }, [dispatch]);
    return (
        <div className={`nc-PageSignUp `} data-nc-id="PageSignUp">
            <div className="container ">
                <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {activationSuccess ? (
                        <div className="w-full grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                            <div
                                className="w-full border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                                <div
                                    className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                    <div className={`w-full`}>
                                        <div
                                            className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                                            <div className="relative">

                                                <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                    {translate("sign-up.success.activation")}
                                                </h2>
                                                <span
                                                    className="block mt-6 text-slate-500 dark:text-slate-400">
                                                {/*{successMessage}*/}
                                                    {/*    Check the email used for registration in order to activate your account.*/}
                                            </span>
                                                <div className="flex space-x-2 mt-6 mb-6">
                                                    <ButtonPrimary
                                                        href="/"
                                                        className="buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                                    >
                                                        {translate("global.destination-button")}
                                                    </ButtonPrimary>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ) : null}
                    {activationFailure ? (
                        <div className="w-full grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                            <div
                                className="w-full border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                                <div
                                    className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                    <div className={`w-full`}>
                                        <div
                                            className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                                            <div className="relative">

                                                <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                    Account activation failed, please check your email and try
                                                    again!
                                                </h2>
                                                <span
                                                    className="block mt-6 text-slate-500 dark:text-slate-400">
                                                {/*{successMessage}*/}
                                                    {/*    Check the email used for registration in order to activate your account.*/}
                                            </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ) : null}
                </h2>
                <div className="max-w-md mx-auto space-y-6 ">
                </div>
            </div>
        </div>
    );
};

export default ActivateAccountPage;
