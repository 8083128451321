import React, {FC, useEffect, useState} from "react";
import {useAppSelector} from "./config/localStorage";

export interface LegalComponentProps {
    className?: string;
    document: any;
}

const LegalComponent: FC<LegalComponentProps> = ({className = "", document}) => {
    var currentLocale = useAppSelector(state => state.locale.currentLocale);
    const [terms, setTerms] = useState([]);

    useEffect(() => {
        if (currentLocale) {
            if (document[currentLocale]) {
                setTerms(document[currentLocale]);
            } else {
                setTerms(document["en"]);
            }
        }
    }, [currentLocale]);

    const Term = ({title, content}: { title: string; content: string[] }) => (
        <div>
            <h2 className="text-2xl font-bold mb-0 mt-6">{title}</h2>
            {content.map((paragraph, index) => (
                <p key={index} className="mb-0">
                    {paragraph}
                </p>
            ))}
        </div>
    );


    return (
        <div className="p-0">
            {terms.map((term: any, index: number) => (
                <Term key={index} title={term.title} content={term.content}/>
            ))}
        </div>
    );
};

export default LegalComponent;
