
export interface IAddress {
    country: string;
    state: string;
    city: string;
    postalCode: string;
    address: string;
}

export const defaultAddressValue: Readonly<IAddress> = {
   country: '',
    state: '',
    city: '',
    postalCode: '',
    address: '',
};
