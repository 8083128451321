import React, {useState} from "react";
import {translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {Alert} from "../shared/components/Alert/Alert";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import {Link} from "react-router-dom";
import PasswordStrengthBar from "../shared/components/password/password-strength-bar";
import {handlePasswordResetFinish} from "./reducers/account/password-reset.reducer";

const ResetPass = ({}) => {
    const dispatch = useAppDispatch();
    const [password, setPassword] = useState('');
    const passwordResetFinish = useAppSelector(state => state.passwordReset.resetPasswordSuccess);
    const passwordResetFailure = useAppSelector(state => state.passwordReset.resetPasswordFailure);
    const isLoading = useAppSelector(state => state.passwordReset.loading);

    const updatePassword = (event: any) => setPassword(event.target.value);

    // @ts-ignore
    const handleValidSubmit = ({newPassword}) => {
        let key = new URLSearchParams(window.location.search).get('key');
        // @ts-ignore
        dispatch(handlePasswordResetFinish({key, newPassword}));
    };

    //if resetPasswordSuccess is true, wait 3 seconds and redirect to login page
    if (passwordResetFinish) {
        setTimeout(() => {
            window.location.href = '/login';
        }, 3000);
    }

    if (passwordResetFailure) {
        setTimeout(() => {
            window.location.href = '/forgot-pass';
        }, 3000);
    }

    return (
        <div className="container mb-12">
            <header className="text-center max-w-2xl mx-auto mb-6">
                <h2 className="mt-12 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Reset password
                </h2>

            </header>

            <div className="max-w-md mx-auto space-y-6">
                {passwordResetFinish ? (
                    <Alert type={'info'}>
                        <span
                            className="block text-center text-neutral-700 dark:text-neutral-300">{translate("forgot-pass.success.reset")}
                            <Link
                                to="/login" className="text-green-600">Sign in</Link></span>

                    </Alert>
                ) : null}
                {passwordResetFailure ? (
                    <Alert type={'error'}>
                        <span
                            className="block text-center text-neutral-700 dark:text-neutral-300">{translate("forgot-pass.error.reset")}
                            <Link
                                to="/forgot-pass"
                                className="text-green-600">{translate("forgot-pass.recover-password")}</Link></span>
                    </Alert>
                ) : null}
                <ValidatedForm
                    //@ts-ignore
                    onSubmit={handleValidSubmit} className="grid grid-cols-1 gap-6">
                    <ValidatedField
                        name="newPassword"
                        label={translate("password-update.new-password-label")}
                        labelClass={'text-neutral-800 dark:text-neutral-200'}
                        placeholder={translate("password-update.new-password-placeholder")}
                        inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                        type="password"
                        validate={{
                            required: {
                                value: true,
                                message: translate('account.password-reset.validate.new-password.required')
                            },
                            minLength: {
                                value: 6,
                                message: translate('account.password-reset.validate.new-password.minlength')
                            },
                            maxLength: {
                                value: 50,
                                message: translate('account.password-reset.validate.new-password.maxlength')
                            },
                        }}
                        onChange={updatePassword}
                        data-cy="resetPassword"
                    />
                    <ValidatedField
                        name="confirmPassword"
                        label={translate("password-update.confirm-new-password-label")}
                        labelClass={'text-neutral-800 dark:text-neutral-200'}
                        placeholder={translate("password-update.confirm-password-placeholder")}
                        inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                        type="password"
                        validate={{
                            required: {value: true, message: translate("global.error.password-confirm")},
                            minLength: {
                                value: 4,
                                message: translate("global.error.password-length-min")
                            },
                            maxLength: {
                                value: 50,
                                message: translate("global.error.password-length-max")
                            },
                            validate: v => v === password || translate("global.error.password-mismatch"),
                        }}
                        data-cy="confirmResetPassword"
                    />
                    <PasswordStrengthBar password={password}/>

                    <ButtonPrimary className={'w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800'}
                                   disabled={isLoading}
                                   type="submit">{translate("forgot-pass.submit-button")}</ButtonPrimary>

                </ValidatedForm>

                {/* ==== */}
                <span className="block text-center text-neutral-700 dark:text-neutral-300">
          {translate("forgot-pass.back-to")} {` `}
                    <Link to="/login" className="text-green-600">
            {translate("global.menu.sign-in")}
          </Link>
                    {` / `}
                    <Link to="/signup" className="text-green-600">
            {translate("forgot-pass.sign-up")}
          </Link>
        </span>
            </div>
        </div>
    );

};

export default ResetPass;
