import React, {FC, useEffect, useRef} from "react";
import {useAppSelector} from "../config/localStorage";
import PrivateRoute from "../../shared/components/PrivateRoute/private-route";
import {Link, useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import AccountDetails from "./AccountDetails";
import AccountOrder from "./AccountOrder";
import AccountPlan from "./AccountPlan";
import AccountPassword from "./AccountPassword";
import {IUser} from "../model/user.model";
import WorldMap from "./WorldMap";
import {translate} from "react-jhipster";
import Voucher from "./AccountVoucher";
import AccountVoucher from "./AccountVoucher";
import Version from "../../shared/header/Version";

export interface CommonLayoutProps {
    children?: Element;
}

const Account: FC<CommonLayoutProps> = ({children = AccountDetails}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const initialized = useRef(false)

    const account: IUser = useAppSelector(state => state.authentication.account);
    let isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    let isLoading = useAppSelector(state => state.authentication.loading);
    const subscriberLoading = useAppSelector(state => state.subscriber.loading);
    const orderLoading = useAppSelector(state => state.order.loading);



    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);


    return (
        isAuthenticated ? (

            <div className="nc-AccountCommonLayout container">
                <div className="">
                    <div className="">

                        {/*<hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>*/}

                        <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
                            <PrivateRoute>
                                <Link
                                    key={2}
                                    to={'/account'}
                                    className={`block py-5 md:py-8 border-b-2 flex-shrink-0 text-sm sm:text-base ${
                                        location.pathname === '/account'
                                            ? `border-primary-500 font-medium text-slate-900 dark:text-slate-200 ${subscriberLoading || orderLoading ? 'disabled' : ''}`
                                            : `border-transparent text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200 ${subscriberLoading || orderLoading ? 'disabled' : ''}`
                                    }`}
                                >
                                    {translate('account.menu.account')}
                                </Link>
                            </PrivateRoute>

                            <PrivateRoute>
                                <Link
                                    key={1}
                                    to={'/account-plans'}
                                    className={`block py-5 md:py-8 border-b-2 flex-shrink-0 text-sm sm:text-base ${
                                        location.pathname === '/account-plans'
                                            ? `border-primary-500 font-medium text-slate-900 dark:text-slate-200 ${subscriberLoading || orderLoading ? 'disabled' : ''}`
                                            : `border-transparent text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200 ${subscriberLoading || orderLoading ? 'disabled' : ''}`
                                    }`}
                                >
                                    {translate('account.menu.esim')}
                                </Link>
                            </PrivateRoute>
                            <PrivateRoute>
                                <Link
                                    key={1}
                                    to={'/account-order'}
                                    className={`block py-5 md:py-8 border-b-2 flex-shrink-0 text-sm sm:text-base ${
                                        location.pathname === '/account-order'
                                            ? `border-primary-500 font-medium text-slate-900 dark:text-slate-200 ${subscriberLoading || orderLoading ? 'disabled' : ''}`
                                            : `border-transparent text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200 ${subscriberLoading || orderLoading ? 'disabled' : ''}`
                                    }`}
                                >
                                    {translate('account.menu.orders')}
                                </Link>
                            </PrivateRoute>
                        </div>
                        <hr className="border-slate-200 dark:border-slate-700"></hr>
                    </div>
                </div>
                <div className=" mx-auto ">
                    {location.pathname === '/account' ?
                        <AccountDetails/> : null
                    }
                    {location.pathname === '/account-order' ?
                        <AccountOrder/> : null
                    }
                    {location.pathname === '/account-plans' ?
                        <AccountPlan/> : null
                    }

                    {location.pathname === '/account-password' ?
                        <AccountPassword/> : null
                    }

                    {location.pathname === '/account-voucher' ?
                        <AccountVoucher/> : null
                    }
                </div>
                <Version/>
            </div>
        ) : null
    );
};

export default Account;
