import {Link} from "react-router-dom";
import {Storage} from "../../app/reducers/storage-util";
import ButtonPrimary from "../components/Button/ButtonPrimary";
import cookieImg from "../img/cookie.svg";
import {Image} from "@chakra-ui/react";
import React, {useState} from "react";
import {Translate} from "react-jhipster";
import ModalViewLegal from "../../app/checkout/ModalViewLegal";

const Cookie = () => {
    const [showCookies, setShowCookies] = useState<boolean>(Storage.local.get('persist:root', true));
    const [showModalViewLegal, setShowModalViewLegal] = useState(false);
    const acceptCookie = () => {
        Storage.local.set('persist:root', false)
        setShowCookies(false);
    }

    return (
        <>{showCookies ? <div className="fixed bottom-0 right-0 mb-4 mr-4 ml-4 xl:w-1/4 lg:w-1/3 z-max">
            <div className="bg-white rounded-lg shadow-lg p-4">
                <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                        <Image src={cookieImg} className="h-6 w-6 mr-2"></Image>
                        <span className="text-gray-700 font-bold text-sm"><Translate
                            contentKey={"cookie.title"}/></span>
                    </div>
                </div>
                <p className="text-gray-600 text-sm pb-2">
                    <Translate contentKey={"cookie.description"}/>
                    <button className={"-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"}
                            onClick={(e) => {
                                setShowModalViewLegal(true);
                            }}>
                        <Translate contentKey={"cookie.link-page"}/></button>
                    .
                </p>
                <ButtonPrimary onClick={acceptCookie}
                               className="buyCardButton w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white">
                    <Translate contentKey={"cookie.accept-button"}/>
                </ButtonPrimary>
            </div>
        </div> : null}

            <ModalViewLegal
                show={showModalViewLegal}
                onCloseModalViewLegal={() => setShowModalViewLegal(false)}
                value={"state.tenant.documents.cookiePolicy"}
            />
        </>
    )
}

export default Cookie;
