import React, {useEffect, useRef, useState} from "react";
import MenuBar from "./MenuBar";
import Logo from "./Logo";
import {Link, useLocation} from "react-router-dom";
import Navigation from "../navigation/Navigation";
import {useAppDispatch, useAppSelector} from "../../app/config/localStorage";
import {clearImpersonateAuthToken, getSession, logout, updateUser} from "../../app/reducers/authentication";
import {useNavigate} from "react-router";
import GoogleAnalytics from "../../GoogleAnalytics";
import {useAppState} from "../../app/config/AppStateContext";
import {setLocale} from "../../app/reducers/locale";
import {UsersIcon} from "@heroicons/react/20/solid";
import VoucherModal from "../components/VoucherModalView";
import LocaleMenu from "./LocaleMenu";
import {isImpersonate} from "../../app/config/axios-interceptor";
import {Helmet, HelmetProvider} from "react-helmet-async";


export interface IHeaderProps {
    currentLocale: string;
}

const Header = (props: IHeaderProps) => {
    const {state} = useAppState();
    const [showVoucherModal, setShowVoucherModal] = useState(false);

    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const account = useAppSelector(state => state.authentication.account);
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [fontUrl, setFontUrl] = useState('');
    const [fontFamily, setFontFamily] = useState('');
    const [faviconUrl, setFaviconUrl] = useState('');
    const [customCss, setCustomCss] = useState('');
    const [customJs, setCustomJs] = useState('');

    const [metaDescription, setMetaDescription] = useState('');
    const liveChatEnabled = state.tenant && state.tenant.liveChatEnabled !== undefined ? state.tenant.liveChatEnabled : true;
    const initialized = useRef(false)

    useEffect(() => {
        // console.log("liveChatEnabled", state.tenant.locale);

        if (!initialized.current) {
            initialized.current = true;

            //     const socket = io("/chat?token=abc123", {
            //         extraHeaders: {
            //             Authorization: `Bearer ${Storage.local.get(AUTH_TOKEN_KEY) || Storage.local.get(AUTH_TOKEN_KEY)}`
            //         }
            //     });
            //
            setFontUrl(state.tenant.font.fontUrl);
            setFontFamily(`${state.tenant.font.fontFamily}, sans-serif`);
            setFaviconUrl(state.tenant.faviconUrl);
            setCustomCss(state.tenant.customCss);
            setMetaDescription(state.tenant.longFormDescription);
            setCustomJs(state.tenant.customJs);
            //
            //     socket.on("connect", () => {
            //         console.log("Connected to server");
            //     });
            //
            //     socket.on("disconnect", (reason) => {
            //         console.log("Reason: ", reason);
            //
            //         console.log("Disconnected from server");
            //     });
            //
            //     socket.on("disconnect", () => {
            //
            //         console.log("Disconnected from server");
            //     });
            //
            //     socket.on("chat", (message: any) => {
            //         console.log(message);
            //         toast.custom(
            //             (t: Toast) => (
            //                 <Transition
            //                     appear
            //                     show={t.visible}
            //                     className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
            //                     enter="transition-all duration-150"
            //                     enterFrom="opacity-0 translate-x-20"
            //                     enterTo="opacity-100 translate-x-0"
            //                     leave="transition-all duration-150"
            //                     leaveFrom="opacity-100 translate-x-0"
            //                     leaveTo="opacity-0 translate-x-20"
            //                 >
            //                     <p className="block" style={{color: state.tenant ? state.tenant.red : "black"}}>
            //                         {JSON.stringify(message)}
            //                     </p>
            //                 </Transition>
            //             ),
            //             {
            //                 position: "top-right",
            //                 duration: 5000,
            //             }
            //         );
            //     });
            //

        }

    }, [state.tenant.font.fontUrl, state.tenant.font.fontFamily, state.tenant.faviconUrl, state.tenant.customCss, state.tenant.customJs, state.tenant.longFormDescription]);

    // @ts-ignore
    const handleLocaleChange = langKey => {
        dispatch(setLocale(langKey));
        const updatedAccount = {...account}
        updatedAccount.langKey = langKey;
        dispatch(updateUser(updatedAccount))
    };

    const renderContent = () => {
        return (
            <div className="h-20 flex justify-between">
                <div className="flex items-center lg:hidden flex-1">
                    <MenuBar/>
                </div>

                <div className="lg:flex-1 flex items-center">
                    <Logo className="flex-shrink-0"/>
                </div>

                <div className="flex-[2] hidden lg:flex justify-center mx-4">
                    <Navigation/>
                </div>


                <div className="flex-1 flex items-center justify-end text-slate-700 dark:text-slate-100 rounded-full">
                    {/*TODO: Translate all pages*/}
                    <LocaleMenu onClick={handleLocaleChange} currentLocale={props.currentLocale}/>
                    {isAuthenticated ?
                        <>
                            {/*<Link to={"/account-voucher"}*/}
                            {/*    // onClick={() => setShowVoucherModal(true)}*/}
                            {/*      className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}>*/}
                            {/*    <svg*/}
                            {/*        className=" w-6 h-6 hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"*/}
                            {/*        fill="currentColor"*/}
                            {/*        xmlns="http://www.w3.org/2000/svg"*/}
                            {/*        viewBox="0 0 512 512">*/}
                            {/*        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>*/}
                            {/*        <g id="SVGRepo_tracerCarrier"></g>*/}
                            {/*        <g id="SVGRepo_iconCarrier">*/}
                            {/*            <g>*/}
                            {/*                <g>*/}
                            {/*                    <path*/}
                            {/*                        d="M495.432,111.165H384.267c-4.427,0-8.017,3.589-8.017,8.017v8.551c0,5.01-4.076,9.086-9.086,9.086 c-5.01,0-9.086-4.076-9.086-9.086v-8.551c0-4.427-3.589-8.017-8.017-8.017H16.568C7.432,111.165,0,118.597,0,127.733v256.534 c0,9.136,7.432,16.568,16.568,16.568h333.495c4.427,0,8.017-3.589,8.017-8.017v-8.551c0-5.01,4.076-9.086,9.086-9.086 c5.01,0,9.086,4.076,9.086,9.086v8.551c0,4.427,3.589,8.017,8.017,8.017h111.165c9.136,0,16.568-7.432,16.568-16.568V127.733 C512,118.597,504.568,111.165,495.432,111.165z M495.967,384.267c0,0.295-0.239,0.534-0.534,0.534H392.284v-0.534 c0-11.048-7.172-20.446-17.102-23.8v-10.405c0-4.427-3.589-8.017-8.017-8.017c-4.427,0-8.017,3.589-8.017,8.017v10.405 c-9.93,3.354-17.102,12.752-17.102,23.8v0.534H16.568c-0.295,0-0.534-0.239-0.534-0.534V127.733c0-0.295,0.239-0.534,0.534-0.534 h325.478v0.534c0,11.048,7.172,20.446,17.102,23.8v10.405c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017 v-10.405c9.93-3.354,17.102-12.752,17.102-23.8v-0.534h103.148c0.295,0,0.534,0.239,0.534,0.534V384.267z"></path>*/}
                            {/*                </g>*/}
                            {/*            </g>*/}
                            {/*            <g>*/}
                            {/*                <g>*/}
                            {/*                    <path*/}
                            {/*                        d="M367.165,188.125c-4.427,0-8.017,3.589-8.017,8.017v17.102c0,4.427,3.589,8.017,8.017,8.017 c4.427,0,8.017-3.589,8.017-8.017v-17.102C375.182,191.715,371.592,188.125,367.165,188.125z"></path>*/}
                            {/*                </g>*/}
                            {/*            </g>*/}
                            {/*            <g>*/}
                            {/*                <g>*/}
                            {/*                    <path*/}
                            {/*                        d="M367.165,239.432c-4.427,0-8.017,3.589-8.017,8.017v17.102c0,4.427,3.589,8.017,8.017,8.017 c4.427,0,8.017-3.589,8.017-8.017v-17.102C375.182,243.021,371.592,239.432,367.165,239.432z"></path>*/}
                            {/*                </g>*/}
                            {/*            </g>*/}
                            {/*            <g>*/}
                            {/*                <g>*/}
                            {/*                    <path*/}
                            {/*                        d="M367.165,290.739c-4.427,0-8.017,3.589-8.017,8.017v17.102c0,4.427,3.589,8.017,8.017,8.017 c4.427,0,8.017-3.589,8.017-8.017v-17.102C375.182,294.328,371.592,290.739,367.165,290.739z"></path>*/}
                            {/*                </g>*/}
                            {/*            </g>*/}
                            {/*            <g>*/}
                            {/*                <g>*/}
                            {/*                    <path*/}
                            {/*                        d="M261.668,173.371c-3.131-3.131-8.207-3.131-11.337,0L96.411,327.292c-3.131,3.131-3.131,8.207,0,11.337 c1.565,1.566,3.617,2.348,5.668,2.348s4.103-0.782,5.668-2.348l153.921-153.921C264.799,181.578,264.799,176.501,261.668,173.371z "></path>*/}
                            {/*                </g>*/}
                            {/*            </g>*/}
                            {/*            <g>*/}
                            {/*                <g>*/}
                            {/*                    <path*/}
                            {/*                        d="M127.733,162.472c-23.281,0-42.221,18.941-42.221,42.221s18.941,42.221,42.221,42.221s42.221-18.941,42.221-42.221 S151.013,162.472,127.733,162.472z M127.733,230.881c-14.44,0-26.188-11.748-26.188-26.188s11.748-26.188,26.188-26.188 s26.188,11.748,26.188,26.188S142.172,230.881,127.733,230.881z"></path>*/}
                            {/*                </g>*/}
                            {/*            </g>*/}
                            {/*            <g>*/}
                            {/*                <g>*/}
                            {/*                    <path*/}
                            {/*                        d="M230.347,265.086c-23.281,0-42.221,18.941-42.221,42.221s18.941,42.221,42.221,42.221s42.221-18.941,42.221-42.221 S253.627,265.086,230.347,265.086z M230.347,333.495c-14.44,0-26.188-11.748-26.188-26.188s11.748-26.188,26.188-26.188 s26.188,11.748,26.188,26.188S244.786,333.495,230.347,333.495z"></path>*/}
                            {/*                </g>*/}
                            {/*            </g>*/}
                            {/*        </g>*/}
                            {/*    </svg>*/}
                            {/*</Link>*/}
                            <Link to="/account"
                                  className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center
                                  
                                  ${
                                      location.pathname.startsWith('/account')
                                          ? "bg-slate-100"
                                          : ""
                                  }
                                  
                                  `}>
                                <svg
                                    className=" w-6 h-6 hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </Link>
                            {/*<Link to={"/#"} onClick={removeSessions}*/}
                            {/*      className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}>*/}
                            {/*    <svg*/}
                            {/*        width="24"*/}
                            {/*        height="24"*/}
                            {/*        viewBox="0 0 24 24"*/}
                            {/*        fill="none"*/}
                            {/*        xmlns="http://www.w3.org/2000/svg"*/}
                            {/*    >*/}
                            {/*        <path*/}
                            {/*            d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"*/}
                            {/*            stroke="currentColor"*/}
                            {/*            strokeWidth="1.5"*/}
                            {/*            strokeLinecap="round"*/}
                            {/*            strokeLinejoin="round"*/}
                            {/*        />*/}
                            {/*        <path*/}
                            {/*            d="M15 12H3.62"*/}
                            {/*            stroke="currentColor"*/}
                            {/*            strokeWidth="1.5"*/}
                            {/*            strokeLinecap="round"*/}
                            {/*            strokeLinejoin="round"*/}
                            {/*        />*/}
                            {/*        <path*/}
                            {/*            d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"*/}
                            {/*            stroke="currentColor"*/}
                            {/*            strokeWidth="1.5"*/}
                            {/*            strokeLinecap="round"*/}
                            {/*            strokeLinejoin="round"*/}
                            {/*        />*/}
                            {/*    </svg>*/}
                            {/*</Link>*/}
                        </>
                        :
                        <Link to="/login"
                              className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center
                                                                ${
                                  location.pathname.startsWith('/login') || location.pathname.startsWith('/signup') || location.pathname.startsWith('/forgot-pass')
                                      ? "bg-slate-100"
                                      : ""
                              }
                              `}>

                            <svg
                                className=" w-6 h-6"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Link>
                    }
                    {
                        isAuthenticated && isImpersonate() ?
                            <Link
                                to={"/admin/users"}
                                onClick={() => {
                                    clearImpersonateAuthToken();
                                    dispatch(getSession());
                                }}
                                className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}>
                                <UsersIcon className={"w-6 h-6"}/>
                            </Link>
                            : null
                    }

                </div>
            </div>
        );
    };

    return (


        <div
            className="nc-MainNav2Logged relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">

<HelmetProvider>
            <Helmet>
                <link href={fontUrl} rel="stylesheet"/>
                <style>{`body { font-family: ${fontFamily}; }`}</style>
                <link rel="icon" href={faviconUrl}/>
                <style>{customCss}</style>
                <meta name="description" content={metaDescription}/>
                <script>{customJs}</script>
                {liveChatEnabled && <script async src="/LiveChat.js"/>}
            </Helmet>
</HelmetProvider>
            <div className="container ">{renderContent()}</div>

            {state.tenant !== null ? (
                <GoogleAnalytics trackingId={state.tenant.googleAnalyticsTrackingCode}/>
            ) : null}

            <VoucherModal show={showVoucherModal} onCloseModalQuickView={() => setShowVoucherModal(false)}/>
        </div>

    );
};

export default Header;
