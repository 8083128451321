import React from "react";
import BgGlassmorphism from "../../../shared/esim/BgGlassmorphism";
import SectionHero from "../../../shared/esim/SectionHero";
import FAQOSSelector from "../esim/FAQOSSelector";
import {translate} from "react-jhipster";
import {useNavigate} from "react-router";

const FirstUse = () => {
    const navigate = useNavigate();
    return (
        <div className={`nc-PageAbout overflow-hidden relative`}>
            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism/>

            <div className="container">
                <SectionHero
                    rightImg={'/cdn/content/first_use.png'}
                    // rightVideoUrl={"https://travel.esimvault.cloud/cdn/content/TDN_581.mp4"}
                    heading={translate('first-use.title')}
                    btnText={translate('first-use.check-compatibility')}
                    subHeading={translate('first-use.heading')}
                    subHeading2=""
                    functionToCall="window.location.href='/compatible-devices';"
                    navigation={() => navigate('/compatible-devices')}
                />

                <FAQOSSelector/>

            </div>
        </div>
    );
};

export default FirstUse;
