import React, {useEffect, useRef, useState} from "react";
import BgGlassmorphism from "../shared/esim/BgGlassmorphism";
import {useAppState} from "./config/AppStateContext";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import {translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {checkCoupon, reset} from "./reducers/coupon";
import SimView from "../shared/components/SimView";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {ICoupon} from "./model/coupon.model";
import {IPlan} from "./model/plan.model";
import {findTemplates} from "./reducers/template";
import {useForm} from "react-hook-form";

const CompatibleDevices = ({}) => {
    const {state} = useAppState();
    const coupon: ICoupon = useAppSelector(state => state.coupon.coupon);
    const isLoading: boolean = useAppSelector(state => state.coupon.loading);
    const isError: boolean = useAppSelector(state => state.coupon.isError);
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const templates: IPlan[] = useAppSelector(state => state.template.templates);
    const {
        handleSubmit,
        trigger,
        formState,
        register,
        setError,
        clearErrors,
        formState: {errors}
    } = useForm({mode: 'onTouched'});

    let dispatch = useAppDispatch();
    const initialized = useRef(false)
    const navigate = useNavigate();
    const location = useLocation();
    const [voucherCode, setVoucherCode] = useState<string>('');

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            dispatch(reset());
            if (templates.length === 0) {
                dispatch(findTemplates());
            }
        }

        if (isError) {
            setError('voucherCode', {message: translate('voucher.error.invalid')});
        } else {
            clearErrors('voucherCode');
        }

        if (!isAuthenticated) {
            navigate('/login', {state: {from: location.pathname}});
            return;
        }
    }, [isError])

    const checkVoucher = (formValues: any) => {
        // console.log('checkVoucher', formValues.voucherCode);
        dispatch(reset());
        setVoucherCode(formValues.voucherCode)
        dispatch(checkCoupon(formValues.voucherCode));
    }

    const activateVoucher = () => {
        const iPaymentIntent = {
            productId: coupon.products[0],
            quantity: 1,
            coupon: voucherCode
        }
        // {"productId":"prod_Pgheb6nehRJoBw","quantity":1,"subscriber":"","coupon":"TAURELIAN"}
        axios.post("/api/checkout/integrated", iPaymentIntent)
            .then(r => {
                console.log(r)
                navigate('/account');
            }).catch(e => {
            console.log(e)
        });
    }

    const renderSimView = () => {
        console.log(templates)
        const template = templates.find(t => t.id === coupon.products[0]);
        console.log(template)
        return <SimView
            name={template?.name}
            desc={template?.description}
            //@ts-ignore
            featuredImage={template?.featuredImage}
            backgroundColor={template?.backgroundColorHex}
            backgroundImageUrl={template?.backgroundImageUrl}
            color={template?.colorHex}
            icon={template?.icon}
            //@ts-ignore
            plan={template}
            allowBuy={false}
            customCallback={activateVoucher}
            customButton={<ButtonPrimary
                className={"bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 w-100"}
                type="submit"
                disabled={isLoading}
                onClick={activateVoucher}
            >
                Activate voucher</ButtonPrimary>}
        />
    }

    return (
        <div className={`nc-PageAbout overflow-hidden relative`}>
            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism/>
            <div className="container mb-12">
                <div className="">
                    <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                        <div
                            className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                            <div
                                className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                <div className={`w-full`}>
                                    <div
                                        className=" relative flex flex-col lg:flex-row dark:bg-slate-800 p-6 lg:p-24">
                                        <div className="relative">
                                            <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                {translate('voucher.title')}
                                            </h2>
                                            <span className="block mt-6 text-slate-500 dark:text-slate-400">
                                                {translate('voucher.description')}
                                        </span>
                                            <div className="grid md:grid-cols-2 gap-5 lg:grid-cols-2 xl:gap-8 mt-12 ">
                                                <div className="relative px-3 py-2">
                                                    <>
                                                        <ValidatedForm
                                                            defaultValues={{voucherCode: ''}}
                                                            //@ts-ignore
                                                            // onSubmit={handleSubmit(checkVoucher)}
                                                            onSubmit={checkVoucher}
                                                            className="flex no-margin-validation-field">

                                                            <ValidatedField
                                                                // register={register}
                                                                //@ts-ignore
                                                                error={errors?.voucherCode}
                                                                name="voucherCode"
                                                                type={'text'}
                                                                inputClass={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-10 px-4 py-3 flex-1 placeholder-gray-300`}
                                                                placeholder={translate('voucher.code-placeholder')}
                                                                validate={{
                                                                    value: {
                                                                        value: true,
                                                                        message: translate('voucher.error.required')
                                                                    },
                                                                    required: {
                                                                        value: true,
                                                                        message: translate('voucher.error.required')
                                                                    },
                                                                }}
                                                            />
                                                            <button
                                                                className={`bg-slate-900 dark:bg-slate-100 hover:bg-slate-900 w-100 text-slate-50 border border-neutral-200 dark:border-neutral-700 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:hover:bg-neutral-800 w-36 flex justify-center items-center transition-colors w-100 ${isLoading ? 'disabled' : ''}`}
                                                                type="submit"
                                                            >
                                                                {translate('voucher.check')}
                                                            </button>
                                                        </ValidatedForm>
                                                    </>
                                                </div>

                                                <div className="relative px-3 py-2">
                                                    {(coupon.id) ?
                                                        <>
                                                            {renderSimView()}
                                                        </> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default CompatibleDevices;
