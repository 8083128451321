import axios, {AxiosResponse} from 'axios';
import {Storage} from './storage-util';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {serializeAxiosError} from './reducer.utils';
import {AppThunk} from "../config/localStorage";
import {IUser} from "../model/user.model";
import {setLocale} from "./locale";

export const AUTH_TOKEN_KEY = 'authenticationToken';
export const IMPERSONATE_AUTH_TOKEN_KEY = 'impersonateAuthenticationToken';


//@ts-ignore
export const clearAuthentication = messageKey => dispatch => {
    clearAuthToken();
    dispatch(authError(messageKey));
    dispatch(clearAuth());
};

export const initialState = {
    loading: false,
    isAuthenticated: false,
    loginSuccess: false,
    loginError: false, // Errors returned from server side
    account: {} as IUser,
    errorMessage: null as unknown as string, // Errors returned from server side
    redirectMessage: null as unknown as string,
    sessionHasBeenFetched: false,
    logoutUrl: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions


export const getSession = (): AppThunk => async (dispatch, getState) => {
    await dispatch(getUser());
    const {account} = getState().authentication;
    if (account && account.langKey) {
        await dispatch(setLocale(account.langKey));
    }
};

export const updateUser = createAsyncThunk(
    'authentication/update_user',
    async (user: IUser) => {
        return axios.put<IUser>('api/user', user);
    },
    {serializeError: serializeAxiosError},
);

export const getUser = createAsyncThunk('authentication/get_user', async () => axios.get<any>('/api/account'), {
    serializeError: serializeAxiosError,
});

interface IAuthParams {
    username: string;
    password: string;
    rememberMe?: boolean;
}

export const googleLogin: (token: any) => AppThunk = (token) => async dispatch => {
    let result = await dispatch(googleAuthenticate(token));
    const response = result.payload as AxiosResponse;
    if (response === undefined) return;
    const jwt = response.data.id_token;
    Storage.local.set(AUTH_TOKEN_KEY, jwt);
    dispatch(getSession());
}

export const googleAuthenticate = createAsyncThunk(
    'authentication/google_login',
    async (token: any) => axios.post('/api/authenticate/google', {token: token}),
    {
        serializeError: serializeAxiosError,
    },
)

export const authenticate = createAsyncThunk(
    'authentication/login',
    async (auth: IAuthParams) => axios.post<any>('/api/authenticate', auth),
    {
        serializeError: serializeAxiosError,
    },
);

export const tokenAuthenticate = createAsyncThunk(
    'authentication/token_login',
    async (auth: string) => axios.post<any>('/api/authenticate/token?token='+auth, auth),
    {
        serializeError: serializeAxiosError,
    },
);

export const impersonate = createAsyncThunk(
    'authentication/impersonate',
    async (userId: string) => axios.post<any>(`/api/authenticate/${userId}`),
    {
        serializeError: serializeAxiosError,
    },
);

export const login: (username: string, password: string, rememberMe?: boolean) => AppThunk =
    (username, password, rememberMe = false) =>
        async dispatch => {
            const result = await dispatch(authenticate({username, password, rememberMe}));
            const response = result.payload as AxiosResponse;
            if (response !== undefined) {
                const jwt = response.data.id_token;
                if (rememberMe) {
                    Storage.local.set(AUTH_TOKEN_KEY, jwt);
                } else {
                    Storage.local.set(AUTH_TOKEN_KEY, jwt);

                }
                dispatch(getSession());
            }
        };

export const tokenLogin: (token: string) => AppThunk =
    (token) =>
        async dispatch => {
            const result = await dispatch(tokenAuthenticate(token));
            const response = result.payload as AxiosResponse;
            if (response !== undefined) {
                const jwt = response.data.id_token;
                Storage.local.set(AUTH_TOKEN_KEY, jwt);
                dispatch(getSession());
            }
        };

export const impersonateUser: (userId: string) => AppThunk = userId => async dispatch => {
    const result = await dispatch(impersonate(userId));
    const response = result.payload as AxiosResponse;
    if (response !== undefined) {
        const jwt = response.data.id_token;
        Storage.local.set(IMPERSONATE_AUTH_TOKEN_KEY, jwt);
        dispatch(getSession());
    }
};

export const clearAuthToken = () => {
    Storage.local.remove(AUTH_TOKEN_KEY);
    Storage.session.remove(AUTH_TOKEN_KEY);
};

export const clearImpersonateAuthToken = () => {
    Storage.local.remove(IMPERSONATE_AUTH_TOKEN_KEY);
    Storage.session.remove(IMPERSONATE_AUTH_TOKEN_KEY);
};

export const logout: () => AppThunk = () => dispatch => {
    clearAuthToken();
    dispatch(logoutSession());
};

export const AuthenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState as AuthenticationState,
    reducers: {
        logoutSession() {
            return {
                ...initialState
            };
        },
        authError(state, action) {
            return {
                ...state,
                redirectMessage: action.payload,
            };
        },
        clearAuth(state) {
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
            };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(googleAuthenticate.fulfilled, state => ({
                ...state,
                loading: false,
                loginError: false,
                loginSuccess: true,
                isAuthenticated: true,
            }))
            .addCase(googleAuthenticate.pending, (state) => {
                state.loading = true;
            })
            .addCase(googleAuthenticate.rejected, (state) => {
                state.loading = false;
                state.loginError = true;
            })
            .addCase(authenticate.fulfilled, state => ({
                ...state,
                loading: false,
                loginError: false,
                loginSuccess: true,
                isAuthenticated: true,
            }))
            .addCase(authenticate.pending, state => {
                state.loading = true;
            })
            .addCase(authenticate.rejected, state => {
                state.loading = false;
                state.loginError = true;
            })
            .addCase(tokenAuthenticate.fulfilled, state => ({
                ...state,
                loading: false,
                loginError: false,
                loginSuccess: true,
                isAuthenticated: true,
            }))
            .addCase(tokenAuthenticate.pending, state => {
                state.loading = true;
            })
            .addCase(tokenAuthenticate.rejected, state => {
                state.loading = false;
                state.loginError = true;
            })
            .addCase(impersonate.fulfilled, state => ({
                ...state,
                loading: false,
                loginError: false,
                loginSuccess: true,
                isAuthenticated: true,
            }))
            .addCase(impersonate.pending, state => {
                state.loading = true;
            })
            .addCase(impersonate.rejected, state => {
                state.loading = false;
                state.loginError = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
                return {
                    ...state,
                    isAuthenticated,
                    loading: false,
                    sessionHasBeenFetched: true,
                    account: action.payload.data,
                };
            })
            .addCase(getUser.rejected, state => {
                state.loading = false;
            })
            .addCase(getUser.pending, state => {
                state.loading = true;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = false;
                state.account = action.payload.data
            })
            .addCase(updateUser.rejected, (state) => {
                state.loading = false;
            })
    },
});

export const {logoutSession, authError, clearAuth} = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
