import React from "react";
import BgGlassmorphism from "../../../shared/esim/BgGlassmorphism";
import SectionHero from "../../../shared/esim/SectionHero";
import FAQAccordionInfo from "./FAQAccordionInfo";

const PageSearch = () => {
    return (
        <div className={`nc-PageAbout overflow-hidden relative`}>
            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism/>

            <div className="container mb-6">
                <SectionHero
                    rightImg={'/cdn/content/first_use.png'}
                    heading="Frequently Asked Questions"
                    btnText=""
                    subHeading="In case you are experiencing issues with your eSIM connection, please run through the set of frequently asked questions below"
                    subHeading2=""
                    functionToCall=""
                />

                <FAQAccordionInfo/>


            </div>

        </div>
    );
};

export default PageSearch;
