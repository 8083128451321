import React from "react";
import {isEmail, translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import {handlePasswordResetInit} from "./reducers/account/password-reset.reducer";
import {clearAuthToken} from "./reducers/authentication";

const PageForgotPass = ({}) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.passwordReset.loading);
    const resetPasswordSuccess = useAppSelector(state => state.passwordReset.resetPasswordSuccess);

    // @ts-ignore
    const handleValidSubmit = ({email}) => {
        clearAuthToken();
        dispatch(handlePasswordResetInit(email));
    };

    //if resetPasswordSuccess is true, wait 3 seconds and redirect to login page
    if (resetPasswordSuccess) {
        setTimeout(() => {
            window.location.href = '/login';
        }, 3000);
    }

    return (
        <div className="container mb-12">

            <header className="text-center max-w-2xl mx-auto mb-6">
                <h2 className="mt-12 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {translate("forgot-pass.title")}
                </h2>
            </header>

            <div className="max-w-md mx-auto space-y-6">
                {resetPasswordSuccess ? (
                    <div>{translate("password-reset.success")}</div>
                ) : null}

                <ValidatedForm
                    //@ts-ignore
                    onSubmit={handleValidSubmit}
                    // className="grid grid-cols-1 gap-6">
                    className={`grid grid-cols-1 gap-6 ${resetPasswordSuccess === true ? "disabled" : ""}`}>
                    <ValidatedField
                        name="email"
                        label={translate("global.email-label")}
                        labelClass={'text-neutral-800 dark:text-neutral-200'}
                        //@ts-ignore
                        placeholder={translate("global.email-placeholder")}
                        type="email"
                        inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                        validate={{
                            required: {value: true, message: translate("global.error.email-required")},
                            minLength: {value: 5, message: translate("global.error.email-length-min")},
                            maxLength: {value: 50, message: translate("global.error.email-length-max")},
                            validate: v => isEmail(v) || translate("global.error.email-invalid"),
                        }}
                        data-cy="emailResetPassword"
                    />
                    <ButtonPrimary
                        className={'buyCardButton w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white'}
                        disabled={loading}
                        type="submit">{translate("forgot-pass.submit-button")}</ButtonPrimary>
                </ValidatedForm>

                {/* ==== */}
                <span
                    className="block text-center text-neutral-700 dark:text-neutral-300">{translate("forgot-pass.back-to")} {` `}
                    <a href="/login" className="text-green-600">{translate("global.menu.sign-in")}</a>
                    {` / `}
                    <a href="/signup" className="text-green-600">{translate("forgot-pass.sign-up")}</a>
                        </span>
            </div>

        </div>
    );

};

export default PageForgotPass;
