import React, {useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import Label from "../../shared/components/Label/Label";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import ESimSwitch from "../../shared/components/MySwitch/ESimSwitch";
import AccountBillingInformation from "./AccountBillingInformation";
import {updateUser} from "../reducers/authentication";
import {listSubscriber} from "../reducers/subscriber.reducer";
import {useAppState} from "../config/AppStateContext";
import {logout} from "../../app/reducers/authentication";
import {useNavigate} from "react-router";
import {translate} from "react-jhipster";


const AccountPage = () => {
    let navigate = useNavigate();

    const dispatch = useAppDispatch();
    const initialized = useRef(false)
    const account = useAppSelector(state => state.authentication.account);
    const loading = useAppSelector(state => state.authentication.loading);
    const {state} = useAppState();

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            // dispatch(listSubscriber());
        }
    }, [dispatch, account]);

    const updateRememberMe = (value: boolean) => {
        const updatedAccount = {...account}
        updatedAccount.rememberMe = value;
        dispatch(updateUser(updatedAccount));
    };

    const updateNewsOffers = (value: boolean) => {
        const updatedAccount = {...account}
        updatedAccount.allowNewsAndOffers = value;
        dispatch(updateUser(updatedAccount));
    };

    const updateGenericPlatformNotification = (value: boolean) => {
        const updatedAccount = {...account}
        updatedAccount.allowGenericPlatformNotifications = value;
        dispatch(updateUser(updatedAccount));
    };

    const updateProductUpdates = (value: boolean) => {
        const updatedAccount = {...account}
        updatedAccount.productUpdates = value;
        dispatch(updateUser(updatedAccount));
    };

    const updateThirdPartySharing = (value: boolean) => {
        const updatedAccount = {...account}
        updatedAccount.allowThirdPartySharing = value;
        dispatch(updateUser(updatedAccount));
    };

    const removeSessions = () => {
        navigate('/');
        dispatch(logout());
    }

    return (
        <div className={`nc-AccountPage `}>
            <div className="grid md:grid-cols-2 gap-5 lg:grid-cols-2 xl:gap-8 mt-12 ">
                <div
                    className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl border border-slate-200 dark:border-slate-700 rounded-lg">
                    <h2
                        className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">{translate("my-account.account-header-1")}</h2>

                    <div className="grid gap-5 xl:gap-8">
                        <div className="relative px-3 py-2">
                            <Label
                                className={'text-neutral-800 dark:text-neutral-200'}>{translate("my-account.account-email")}</Label>
                            <span className={"float-right"}>{account.email}</span>
                            <p className="text-neutral-500 dark:text-neutral-400 text-xs justify w-1/2">
                                {account.isSocialLogin ? translate("my-account.account-email-social") : translate("my-account.account-email-email")}
                            </p>

                        </div>
                        <div className="relative px-3 py-2">

                            <div>
                                <Label>{translate("my-account.last-login-location")}</Label>
                                <span className={"float-right"}>{account.lastLocation || 'Unknown'}</span>
                                <p className="text-neutral-500 dark:text-neutral-400  text-xs  w-1/2">
                                    {translate("my-account.last-login-location-desc")}
                                </p>
                            </div>
                        </div>
                        <div className="relative px-3 py-2">
                            {account.rememberMe}
                            <ESimSwitch
                                label={translate("my-account.keep-session")}
                                desc={translate("my-account.keep-session-desc")}
                                onChange={updateRememberMe}
                                enabled={account.rememberMe}
                                disabled={loading}
                            />

                        </div>
                        <h2
                            className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                            {translate("my-account.account-control-header-1")}
                        </h2>

                        <div className="grid gap-5 md:grid-cols-1 lg:grid-cols-2 xl:gap-8">
                            <div className="flex justify-center px-3 py-2 gap-5 xl:gap-8">
                                <ButtonPrimary
                                    className="buyCardButton w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-center text-white"
                                    href={'/account-password'}>{translate("my-account.update-password-button")}</ButtonPrimary>
                            </div>
                            <div className="flex justify-center px-3 py-2">
                                <ButtonPrimary
                                    className="w-full bg-yellow-600 dark:bg-yellow-100 hover:bg-yellow-700 text-center text-white"
                                    href={'/#'}
                                    onClick={removeSessions}>{translate("my-account.logout-button")}</ButtonPrimary>
                            </div>

                        </div>


                    </div>

                </div>
                <div
                    className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl border border-slate-200 dark:border-slate-700 rounded-lg">
                    <h2
                        className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">{translate("my-account.notification-header-1")}</h2>

                    <div className="grid gap-5 xl:gap-8">

                        <div className="relative px-3 py-2">
                            {account.allowGenericPlatformNotifications}
                            <ESimSwitch
                                label={translate("my-account.generic-notifications")}
                                desc={translate("my-account.generic-notification-desc")}
                                onChange={updateGenericPlatformNotification}
                                enabled={account.allowGenericPlatformNotifications}
                                disabled={loading}
                            />

                        </div>
                        <div className="relative px-3 py-2">
                            {account.productUpdates}
                            <ESimSwitch
                                label={translate("my-account.product-updates")}
                                desc={translate("my-account.product-updates-desc")}
                                onChange={updateProductUpdates}
                                enabled={account.productUpdates}
                                disabled={loading}
                            />

                        </div>
                        <div className="relative px-3 py-2">
                            {account.allowNewsAndOffers}
                            <ESimSwitch
                                label={translate("my-account.news-offers")}
                                desc={translate("my-account.news-offers-desc")}
                                onChange={updateNewsOffers}
                                enabled={account.allowNewsAndOffers}
                                disabled={loading}
                            />

                        </div>
                        <div className="relative px-3 py-2">
                            {account.allowThirdPartySharing}
                            <ESimSwitch
                                label={translate("my-account.third-party")}
                                desc={translate("my-account.third-party-desc")}
                                onChange={updateThirdPartySharing}
                                enabled={account.allowThirdPartySharing}
                                disabled={loading}
                            />

                        </div>


                    </div>
                </div>

            </div>

            <div className="grid mt-12 mb-12 space-y-6 border border-slate-200 dark:border-slate-700 rounded-lg">

                <AccountBillingInformation/>
            </div>
        </div>
    );
}

export default AccountPage;
