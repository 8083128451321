import React, {FC, useRef, useState} from "react";
import {defaultPlanValue, IPlan} from "../../app/model/plan.model";
import {useNavigate} from "react-router";
import ButtonPrimary from "./Button/ButtonPrimary";
import CountryFlags from "../../app/CountryFlags";
import Amount from "./Amount";
import {useAppState} from "../../app/config/AppStateContext";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import {Link, useLocation} from "react-router-dom";

export interface SimViewProps {
    className?: string;
    featuredImage?: string;
    name?: string;
    desc?: string;
    backgroundColor?: string;
    backgroundImageUrl?: string;
    color?: string;
    icon?: string;
    plan: IPlan;
    allowBuy?: boolean;
    customButton?: JSX.Element;
}


export function humanFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    //: ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

const SimView: FC<SimViewProps> = ({
                                       className = "",
                                       featuredImage = 'CATS_DISCOVER[2].featuredImage',
                                       // name = 'CATS_DISCOVER[2].name',
                                       // desc = 'CATS_DISCOVER[2].desc',
                                       backgroundColor = 'CATS_DISCOVER[2].backgroundColor',
                                       backgroundImageUrl = 'CATS_DISCOVER[2].backgroundImageUrl',
                                       color = 'CATS_DISCOVER[2].color',
                                       icon = 'CATS_DISCOVER[2].icon',
                                       plan = defaultPlanValue,
                                       allowBuy = true,
                                       customButton
                                   }) => {
    const location = useLocation();
    let refBackgroundColor = useRef<string>(backgroundColor);
    let refBackgroundImageUrl = useRef<string>(backgroundImageUrl);
    let refColor = useRef<string>(color);
    const {state} = useAppState();

    const dynamicColorStyle = {
        background: refBackgroundColor.current,
        backgroundImage: refBackgroundImageUrl.current ? `url(/api/content/image/${refBackgroundImageUrl.current})` : '',
        backgroundSize: 'cover', // You may need to adjust this based on your requirements
        color: refColor.current,
    };

    const dynamicColorStyleInner = {
        color: color,
    };

    featuredImage = icon;

    return (
        <>
            <div
                className={`relative w-full aspect-w-16 aspect-h-11 sm:aspect-h-9 h-0 rounded-2xl overflow-hidden group bg-yellow-50`}
                style={dynamicColorStyle}
            >
                <div>
                    <div className="absolute xsm:inset-5 sm:inset-5 flex flex-col" style={dynamicColorStyleInner}>
                        {backgroundImageUrl === "" && featuredImage !== undefined && featuredImage !== "" && featuredImage !== "null" && featuredImage !== null ? (
                            <img
                                alt=""
                                src={featuredImage}
                                className="absolute end-0 w-1/2 max-w-[260px] h-full object-contain drop-shadow-xl"
                            />
                        ) : null}
                    </div>
                </div>
                <span style={dynamicColorStyleInner}
                      className="opacity-0 group-hover:opacity-40 absolute inset-0 bg-black/10 transition-opacity"></span>

                <div>
                    <div className="absolute  xsm:inset-5 sm:inset-5 flex flex-col" style={dynamicColorStyleInner}>

                        <h2 className="text-xl sm:text-2xl xsm:text-2xl font-semibold mb-3">
                            <Link
                                to={`/plan/${plan.id}`}
                                className={`cursor-pointer nc-CardCategory3 block ${className}`}
                            >  {plan.name}</Link>
                        </h2>
                        <div className={`p-2 text-slate-500 dark:text-slate-400 overflow-auto`}
                             style={dynamicColorStyleInner}>
                            {state.tenant.productManagement.showProductCountryList ?
                            <CountryFlags zones={plan.operators}></CountryFlags> : null}
                        </div>

                        <div className="flex justify-between mt-auto">
                            <div className="flex w-1/2">
                                {allowBuy ?
                                    <Link
                                        to={`/plan/${plan.id}`}
                                    >
                                        <ButtonPrimary
                                            className={`buyCardButton test shadow-xl`}
                                        >
                                            <Amount amount={plan.price} currency={plan.currency || ''}/>
                                        </ButtonPrimary>
                                    </Link> : null
                                }
                                {customButton ?
                                    customButton : null
                                }
                            </div>

                            <div className="flex w-1/2 justify-end">

                                <Link to={`/plan/${plan.id}${location.search}`} aria-label={plan.name}>
                                    <InformationCircleIcon className="h-12 w-12 "
                                                           style={{"padding": "0px!important"}}/>
                                </Link>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>
    );
};

export default SimView;
