import React, {useEffect, useRef, useState} from "react";
import {useAppState} from "../../app/config/AppStateContext";
import ButtonSecondary from "./Button/ButtonSecondary";
import ModalViewQR from "./ModalViewQR";
import {ISubscriber} from "../../app/model/subscriber.model";
import axios from "axios";
import {IPrepaidPackage} from "../../app/model/prepaid-package.model";
import {formatDate, keyGenerator} from "../../app/config/utils";
import ProgressBar from "@ramonak/react-progress-bar";
import Nav from "./Nav/Nav";
import CountryFlags from "../../app/CountryFlags";
import {humanFileSize} from "./SimView";
import {Disclosure} from "@headlessui/react";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import {useAppDispatch} from "../../app/config/localStorage";
import {updateSubscriber} from "../../app/reducers/subscriber.reducer";
import Label from "./Label/Label";
import NavItem from "./NavItem/NavItem";
import {ArrowRightCircleIcon} from "@heroicons/react/24/solid";
import LoadingSpinner from "../../app/LoadingSpinner";
import {PencilSquareIcon} from "@heroicons/react/20/solid";
import {translate, ValidatedField, ValidatedForm} from "react-jhipster";


interface IPlanDetails {
    subscriber: ISubscriber;
    reload?: boolean;
}

export const isActive = (prepaidPackage: IPrepaidPackage) => {
    if (prepaidPackage.useddatabyte === prepaidPackage.pckdatabyte) {
        return false;
    }
    if (prepaidPackage && !prepaidPackage.active) {
        return false;
    }
    let time = new Date(prepaidPackage.tsexpirationutc).getTime();
    let now = new Date().getTime();
    if (!prepaidPackage.tsexpirationutc) {
        return true;
    }
    return now <= time;
}

const PlanDetails = (props: IPlanDetails) => {
    const {state} = useAppState();
    const initialized = useRef(false)
    const [isOpenModalViewQR, setIsOpenModalViewQR] = useState('');
    const [prepaidPackages, setPrepaidPackages] = useState<IPrepaidPackage[]>([]);
    const [isRenaming, setIsRenaming] = useState(false);
    const [tabActive, setTabActive] = useState(0);
    const dispatch = useAppDispatch();
    const [simName, setSimName] = useState(props.subscriber.company);

    useEffect(() => {
        if(props.reload){
            initialized.current = false;
        }
        if (!initialized.current) {
            initialized.current = true;
            setSimName(props.subscriber.company !== null && props.subscriber.company !== undefined && props.subscriber.company !== '' ?
                props.subscriber.company :
                props.subscriber.imsiList[0].iccid)
            axios.get<IPrepaidPackage[]>(`/api/user/sim/${props.subscriber.sim.activationCode}/usage`)
                .then(response => {
                    let prepaidPackages: IPrepaidPackage[] = response.data;
                    // prepaidPackages = prepaidPackages
                    //     .sort((a, b) => new Date(b.tsexpirationutc).getTime() - new Date(a.tsexpirationutc).getTime());
                    setPrepaidPackages(prepaidPackages);
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, [props.reload]);

    const getCompleted = (prepaidPackage: IPrepaidPackage) => {
        return parseFloat((prepaidPackage.useddatabyte / prepaidPackage.pckdatabyte * 100).toFixed(0));
        // return 71;
    }

    const getRemainingDays = (prepaidPackage: IPrepaidPackage) => {
        if (prepaidPackage.tsexpirationutc === null) {
            return translate("global.text.not-active-yet")
        }
        return translate("global.text.expires-in") + ' ' + Math.round((new Date(prepaidPackage.tsexpirationutc).getTime() - new Date().getTime()) / 1000 / 3600 / 24);
    }

    const inVoiceAllowed = () => {
        return props.subscriber.allowedMoc && props.subscriber.allowedMtc
    }

    const isSmsAllowed = () => {
        return props.subscriber.allowedMosms && props.subscriber.allowedMtsms
    }

    const useOutsideClick = (callback: () => void) => {
        const ref = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const handleClickOutside = (event: MouseEvent) => {
                if (ref.current && !ref.current.contains(event.target as Node)) {
                    callback();
                }
            };

            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [callback]);

        return ref;
    };

    const renderActivePackage = (prepaidPackage: IPrepaidPackage, index: number) => {
        const panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6";

        return (
            <div key={keyGenerator()}
                    className={"border border-slate-950 dark:border-slate-700 rounded-b-3xl overflow-hidden z-0 mt-6"}>

                    <Disclosure key={index} defaultOpen={index === 0}>
                        {({open}) => (
                            <>

                                <Disclosure.Button
                                    className="flex grid-cols-2 justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80
                            hover:bg-slate-200/60 dark:hover:bg-slate-700
                            focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75
                            bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-slate-50">

                            <span
                                className={'col-span-1'}>{prepaidPackage.product.name}
                                <sup> {getRemainingDays(prepaidPackage)} {prepaidPackage.tsexpirationutc !== null ? translate("global.text.days") : null} </sup>
                            </span>
                                    <div className={'col-span-1'}>
                                        {!open ? (
                                            <PlusIcon
                                                className="w-4 h-4 text-slate-50 dark:text-slate-400 la-pull-right"/>
                                        ) : (
                                            <MinusIcon
                                                className="w-4 h-4 text-slate-50 dark:text-slate-400 la-pull-right"/>
                                        )}
                                    </div>

                                </Disclosure.Button>
                                <Disclosure.Panel
                                    className={panelClassName + ""}
                                >
                                    <div
                                        className="flex-1 ">

                                        <div
                                            // className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80"
                                        >
                                            {/*                  <div className="flex font-semibold justify-between pb-4">*/}
                                            {/*                      <span>{prepaidPackage.product.name}</span>*/}
                                            {/*                      <span className="text-slate-900 dark:text-slate-200">*/}
                                            {/*   {prepaidPackage.perioddays} days*/}
                                            {/*</span>*/}
                                            {/*                  </div>*/}


                                            <div className="   py-2">
                                                <div>
                                                    <Label>{translate("global.text.activation-date")}</Label>
                                                    <span
                                                        className={"float-right w-1/2 right-0 text-right"}>
                                                        {prepaidPackage.tsactivationutc ? formatDate(new Date(prepaidPackage.tsactivationutc)) : translate("global.text.at-first-usage")}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="flex    py-2">
                                                <div className={"w-full"}>
                                                    <Label>{translate("global.text.coverage")}</Label>
                                                    <span
                                                        className={"float-right w-1/2 right-0 text-right"}>
                                                <CountryFlags
                                                    zones={prepaidPackage.operators}></CountryFlags>
                                            </span>
                                                </div>
                                            </div>

                                            <div className="    py-2">
                                                <div>
                                                    <Label>{translate("global.text.usage")}</Label>
                                                    <span
                                                        className={"float-right w-1/2 right-0 text-right"}>
                                                {humanFileSize(prepaidPackage.useddatabyte)} {translate("global.text.from")} {humanFileSize(prepaidPackage.pckdatabyte)}

                                            </span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className=""
                                         style={{
                                             width: "calc(100% + 400px)",
                                             marginLeft: "-200px",
                                             marginRight: "-200px"
                                         }}>
                                        <div className={""}
                                             style={{
                                                 marginTop: "25px",
                                                 width: "calc(100% - 360px)",
                                                 marginLeft: "180px"
                                             }}>
                                            <ProgressBar
                                                isLabelVisible={false}
                                                labelSize={"0.5rem"}
                                                labelAlignment={"right"}
                                                labelClassName={"text-slate-50 dark:text-slate-400 ml-5 mr-5 pl-5 pr-5"}
                                                // customLabel={humanFileSize(prepaidPackage.useddatabyte) + "/" + humanFileSize(prepaidPackage.pckdatabyte)}
                                                completed={getCompleted(prepaidPackage)}
                                                borderRadius="0"
                                                // completed={10}
                                                baseBgColor={
                                                    state.tenant
                                                        ? state.tenant.green
                                                            ? getCompleted(prepaidPackage) < 60
                                                                ? state.tenant.green
                                                                : getCompleted(prepaidPackage) < 80
                                                                    ? state.tenant.yellow
                                                                    : state.tenant.red
                                                            : "#f87171"
                                                        : "#f87171"
                                                }
                                                bgColor="grey"
                                                height={"100%"}

                                            />
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}

                    </Disclosure>
            </div>)
    }

    const renderExpiredPackage = (prepaidPackage: IPrepaidPackage, index: number) => {
        const panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6";

        return (
            <div key={keyGenerator()}>
                <div
                    className={"border border-slate-950 dark:border-slate-700 rounded-b-3xl overflow-hidden z-0 mt-6"}>
                    <Disclosure key={index} defaultOpen={index === 0}>
                        {({open}) => (
                            <>
                                <Disclosure.Button
                                    className="flex grid-cols-2 justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80
                            hover:bg-slate-200/60 dark:hover:bg-slate-700
                            focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75
                            bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-slate-50">
                            <span
                                className={'col-span-1'}>{prepaidPackage.product.name}
                            </span>
                                    <div className={'col-span-1'}>
                                        {!open ? (
                                            <PlusIcon
                                                className="w-4 h-4 text-slate-50 dark:text-slate-400 la-pull-right"/>
                                        ) : (
                                            <MinusIcon
                                                className="w-4 h-4 text-slate-50 dark:text-slate-400 la-pull-right"/>
                                        )}
                                    </div>
                                </Disclosure.Button>
                                <Disclosure.Panel
                                    className={panelClassName}
                                >
                                    <div className="flex-1">

                                        <div
                                            className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                                            <div className="flex font-semibold justify-between pb-4">
                                                <span>{prepaidPackage.product.name}</span>
                                                <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {formatDate(new Date(prepaidPackage.tsactivationutc))} - {formatDate(new Date(prepaidPackage.tsexpirationutc))}
                  </span>
                                            </div>

                                            <div className="flex justify-between py-2">
                                                {humanFileSize(prepaidPackage.useddatabyte)} {translate("global.text.from")} {humanFileSize(prepaidPackage.pckdatabyte)}
                                            </div>
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>
            </div>)
    }

    const renderPendingPackage = (prepaidPackage: IPrepaidPackage) => {
        return (<div className="flex-1">

            <div
                className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex font-semibold justify-between pb-4">
                    <span>{prepaidPackage.product.name}</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {prepaidPackage.perioddays} days
                  </span>
                </div>

                <div className="flex justify-between py-2">
                    {humanFileSize(prepaidPackage.pckdatabyte)}
                </div>

                <div className="flex justify-between py-2">
                    <span>{translate("global.text.coverage")}</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200">
                                    <CountryFlags zones={prepaidPackage.operators}></CountryFlags>
                                </span>
                </div>
            </div>
        </div>)
    }

    // @ts-ignore
    // const ref = useOutsideClick(() => {})

    return (
        <div
            className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12 border border-slate-200 dark:border-slate-700 rounded-lg">
            <div className="border-slate-200 dark:border-slate-700  p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl ">

                <div className="grid md:grid-cols-4 gap-5 lg:grid-cols-4  xl:gap-8">
                    <div className="grid col-span-3 gap-5 xl:gap-8 pb-6 ">
                        <h2
                            className="font-semibold leading-none text-neutral-900 dark:text-neutral-200">
                            {/*{subscriber.imsiList[0].iccid}*/}
                            {!isRenaming ?
                                <a onClick={() => {
                                    setIsRenaming(true)
                                }}>{simName} </a> :
                                <ValidatedForm className={"flex no-margin-validation-field"}
                                               onSubmit={(e) => {
                                                   setSimName(e.simName)
                                                   dispatch(updateSubscriber({
                                                       activationCode: props.subscriber.sim.activationCode,
                                                       payload: {name: e.simName}
                                                   }));
                                                   setIsRenaming(false);
                                               }}>

                                    <ValidatedField
                                        defaultValue={simName}
                                        name="simName"
                                        labelClass={'text-neutral-800 dark:text-neutral-200'}
                                        placeholder={translate("global.sim-name-placeholder")}
                                        type="text"
                                        inputClass={'block w-80 border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 inline-block placeholder-gray-300'}
                                        validate={{
                                            minLength: {
                                                value: 1,
                                                message: translate("global.error.sim-name-length-min")
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: translate("global.error.sim-name-length-max")
                                            },
                                            pattern: {
                                                value: /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]$/,
                                                message: translate("global.error.sim-name-invalid")
                                            }
                                        }}
                                        data-cy="simName"
                                    />
                                    <button><ArrowRightCircleIcon
                                        className=" w-8 h-8 hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200 inline-block ml-3"/>
                                    </button>
                                </ValidatedForm>
                            }
                            {!isRenaming ?
                                <sup>
                                    <PencilSquareIcon onClick={() => {
                                        setIsRenaming(true)
                                    }}
                                                      className=" w-6 h-6 hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200 inline-block  ml-3"/>
                                </sup> :
                                null}
                        </h2>
                    </div>
                    <div className="grid col-span-1 gap-5 xl:gap-8 pb-6">
                        <div className="gap-5 xl:gap-8">
                            {/*{isActive(subscriberUsage) ?*/}
                            {/*<ButtonPrimary*/}
                            {/*    onClick={() => {*/}
                            {/*        setIsRenaming(false);*/}
                            {/*        dispatch(updateSubscriber({*/}
                            {/*            activationCode: subscriber.sim.activationCode,*/}
                            {/*            payload: {name: simName}*/}
                            {/*        }))*/}
                            {/*    }}*/}
                            {/*    className={!isRenaming ? 'hidden' : '' + ' bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 float-right'}*/}
                            {/*    sizeClass="py-2.5 px-4 sm:px-6"*/}
                            {/*    fontSize="text-sm font-medium">*/}
                            {/*    Save*/}
                            {/*</ButtonPrimary>*/}
                            <ButtonSecondary
                                onClick={() => setIsOpenModalViewQR(props.subscriber.sim.activationCode)}
                                className={isRenaming ? 'hidden' : '' + 'float-left lg:float-right'}
                                sizeClass="py-2.5 px-4 sm:px-6"
                                fontSize="text-sm font-medium">
                                🏁 {translate("global.text.view-qr")}
                            </ButtonSecondary>
                        </div>
                    </div>
                </div>
                <hr className="border-slate-200 dark:border-slate-700"></hr>

                <div
                    className="pt-3">
                    <ModalViewQR
                        key={props.subscriber.subscriberId}
                        show={isOpenModalViewQR === props.subscriber.sim.activationCode}
                        onCloseModalViewAllReviews={() => {
                            setIsOpenModalViewQR('');
                        }}
                        value={'LPA:1$' + props.subscriber.sim.smdpServer + '$' + props.subscriber.sim.activationCode}
                    />
                    <div className=" gap-5 xl:gap-8 grid ">

                        <div className="relative px-3 py-2">

                            <div>
                                <Label>{translate("global.text.voice")}</Label>
                                <span
                                    className={"float-right"}>                         {inVoiceAllowed() ? <>✅</> : <>⛔</>}</span>

                                <p className="text-neutral-500 dark:text-neutral-400  text-xs w-1/2">
                                    {translate("global.text.voice-details")}
                                </p>

                            </div>
                        </div>

                        <div className="relative px-3 py-2">

                            <div>
                                <Label>{translate("global.text.sms")}</Label>
                                <span
                                    className={"float-right"}>                     {isSmsAllowed() ? <>✅</> : <>⛔</>}</span>

                                <p className="text-neutral-500 dark:text-neutral-400  text-xs w-1/2">
                                    {translate("global.text.sms-details")}
                                </p>

                            </div>
                        </div>

                        <div className="relative px-3 py-2">

                            <div>
                                <Label>{translate("global.text.pin-puk")}</Label>
                                <span className={"float-right"}>{props.subscriber.sim.pin1}, {props.subscriber.sim.puk1}</span>

                                <p className="text-neutral-500 dark:text-neutral-400  text-xs w-1/2">
                                    {translate("global.text.pin-puk-details")}
                                </p>

                            </div>
                        </div>

                        <div className="relative px-3 py-2">

                            <div>
                                <Label>{translate("global.text.last-visited-location")}</Label>
                                <span className={"float-right"}>{props.subscriber.lastCountry}</span>

                                <p className="text-neutral-500 dark:text-neutral-400  text-xs w-1/2">
                                    {translate("global.text.last-visited-location-details")}
                                </p>

                            </div>
                        </div>
                        {/*<Nav*/}
                        {/*    className="sm:space-x-2"*/}
                        {/*    containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"*/}
                        {/*>*/}
                        {/*    {["Active", "Expired"*/}
                        {/*        // , "Pending for activation"*/}
                        {/*    ].map(*/}
                        {/*        (item, index) => (*/}
                        {/*            <a*/}
                        {/*                key={index}*/}
                        {/*                // isActive={tabActive === item}*/}
                        {/*                onClick={() => setTabActive(item)}*/}
                        {/*                className={`block py-5 md:py-8 border-b-2 flex-shrink-0 text-sm sm:text-base cursor-pointer ${*/}
                        {/*                    tabActive === item*/}
                        {/*                        ? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"*/}
                        {/*                        : "border-transparent text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"*/}
                        {/*                }`}*/}
                        {/*            >*/}
                        {/*                {item}*/}
                        {/*            </a>*/}
                        {/*        )*/}
                        {/*    )}*/}
                        {/*</Nav>*/}

                        <div
                            className="mb-0">

                            {/*<Nav*/}
                            {/*    className="sm:space-x-2"*/}
                            {/*    containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"*/}
                            {/*>*/}
                            {/*    {["Active", "Expired"*/}
                            {/*        // , "Pending for activation"*/}
                            {/*    ].map(*/}
                            {/*        (item, index) => (*/}
                            {/*            <a*/}
                            {/*                key={index}*/}
                            {/*                // isActive={tabActive === item}*/}
                            {/*                onClick={() => setTabActive(item)}*/}
                            {/*                className={`block py-5 md:py-8 border-b-2 flex-shrink-0 text-sm sm:text-base cursor-pointer ${*/}
                            {/*                    tabActive === item*/}
                            {/*                        ? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"*/}
                            {/*                        : "border-transparent text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"*/}
                            {/*                }`}*/}
                            {/*            >*/}
                            {/*                {item}*/}
                            {/*            </a>*/}
                            {/*        )*/}
                            {/*    )}*/}
                            {/*</Nav>*/}
                            <div className="relative">
                                {prepaidPackages.length === 0 ? <LoadingSpinner></LoadingSpinner> : <>
                                    <div key={keyGenerator()} className=" grid relative pt-2">
                                        <Label key={keyGenerator()}>
                                            <Nav
                                            key={keyGenerator()}
                                            className="sm:space-x-2"
                                            containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
                                        >
                                            {["🟢 " + translate("global.text.active-plans"), "🔴 " + translate("global.text.expired-plans")].map(
                                                (item, index) => (
                                                    <NavItem
                                                        key={keyGenerator()}
                                                        isActive={tabActive === index}
                                                        onClick={() => setTabActive(index)}
                                                    >
                                                        {item}
                                                    </NavItem>
                                                )
                                            )}
                                        </Nav>
                                        </Label>

                                        <span className={"grid "}>
                                    {tabActive === 0 ?
                                        prepaidPackages.filter((prepaidPackages: IPrepaidPackage, index: number) => (isActive(prepaidPackages)))
                                            .map((prepaidPackages: IPrepaidPackage, index: number) => (
                                                renderActivePackage(prepaidPackages, index)
                                            )) : null
                                    }
                                            {tabActive === 1 ?
                                                prepaidPackages.filter((prepaidPackages: IPrepaidPackage, index: number) => (!isActive(prepaidPackages)))
                                                    .map((prepaidPackages: IPrepaidPackage, index: number) => (
                                                        renderExpiredPackage(prepaidPackages, index)
                                                    )) : null
                                            }

                                </span>


                                    </div>

                                </>}

                            </div>

                        </div>

                        {/*{*/}
                        {/*    tabActive === "Pending for activation" ?*/}
                        {/*        prepaidPackages.filter((prepaidPackages: IPrepaidPackage, index: number) => (isActive(prepaidPackages) && notActiveYet(prepaidPackages)))*/}
                        {/*            .map((prepaidPackages: IPrepaidPackage, index: number) => (*/}
                        {/*                renderPendingPackage(prepaidPackages)*/}
                        {/*            )) : null*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlanDetails;
