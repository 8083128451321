import React, {FC, ReactNode} from "react";
import twFocusClass from "../../utils/twFocusClass";

export interface NavItem2Props {
    className?: string;
    radius?: string;
    onClick?: () => void;
    isActive?: boolean;
    renderX?: ReactNode;
    children?: React.ReactNode;
    disabled?: () => boolean;
}

const NavItem: FC<NavItem2Props> = ({
                                        className = "px-3.5 py-2 text-sm sm:px-7 sm:py-3 ",
                                        radius = "rounded-full",
                                        children,
                                        onClick = () => {
                                        },
                                        isActive = false,
                                        renderX,
                                        disabled = () => {
                                            return false
                                        },
                                    }) => {

    return (
        <li className="nc-NavItem2 relative" data-nc-id="NavItem2">
            {renderX && renderX}
            <button
                className={`block font-medium whitespace-nowrap ${className} ${radius} ${
                    isActive
                        ? "bg-slate-900 text-slate-50"
                        : "text-slate-600 dark:text-slate-400 dark:hover:text-slate-100 hover:text-slate-900 "
                } ${twFocusClass()}` + (disabled() ? ' disabled' : '')}
                onClick={() => {
                    onClick && onClick();
                }}
            >
                {children}
            </button>
        </li>
    );
};

export default NavItem;
