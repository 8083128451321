import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {translate, Translate} from 'react-jhipster';
import {useAppDispatch, useAppSelector} from "../../config/localStorage";
import {ASC, DESC, ITEMS_PER_PAGE, SORT} from "../../config/constants";
import {overridePaginationStateWithQueryParams} from "../../config/entity-utils";
import {getPaginationState} from "../../config/pagination-utils";
import {getQROnboards} from "../../reducers/qr-onboard-management.reducer";
import {Pagination} from "../../../shared/utils/PaginationProps";
import {IQrOnboard} from "../../../shared/model/qr-onboard.model";
import DynamicHeroIcon from "../../../shared/utils/DynamicHeroIcon";
import DateFormat from "../../../shared/utils/DateFormatUtils";
import NcDropDown, {NcDropDownItem} from "../../../shared/NcDropDown";

export const OnboardingManagement = () => {
    const dispatch = useAppDispatch();
    const pageLocation = useLocation();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState(
        //@ts-ignore
        overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'email'), pageLocation.search),
    );


    const getUsersFromProps = () => {
        dispatch(
            getQROnboards({
                page: pagination.activePage - 1,
                size: pagination.itemsPerPage,
                sort: `${pagination.sort},${pagination.order}`,
                query: pagination.query,
            }),
        );
        const endURL = `?search=${pagination.query}&page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
        if (pageLocation.search !== endURL) {
            navigate(`${pageLocation.pathname}${endURL}`);
        }
    };

    useEffect(() => {
        getUsersFromProps();
    }, [pagination]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getUsersFromProps();
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [pagination.query])

    useEffect(() => {
        const params = new URLSearchParams(pageLocation.search);
        const page = params.get('page');
        const sortParam = params.get(SORT);
        let query = params.get('search');
        query = query ? query : '';
        if (page && sortParam) {
            const sortSplit = sortParam.split(',');
            setPagination({
                ...pagination,
                activePage: +page,
                sort: sortSplit[0],
                order: sortSplit[1],
                query: query,
            });

        }
    }, [pageLocation.search]);

    const sort = (p: string) => () =>
        setPagination({
            ...pagination,
            order: pagination.order === ASC ? DESC : ASC,
            sort: p,
        });

    const handlePagination = (currentPage: number) =>
        setPagination({
            ...pagination,
            activePage: currentPage,
        });

    const qrOnboards = useAppSelector(state => state.qrOnboardManagement.qrOnboards);
    const totalItems = useAppSelector(state => state.qrOnboardManagement.totalItems);
    const loading = useAppSelector(state => state.qrOnboardManagement.loading);
    const getSortIconByFieldName = (fieldName: string) => {
        const sortFieldName = pagination.sort;
        const order = pagination.order;
        if (sortFieldName !== fieldName) {
            return 'ChevronUpDownIcon';
        } else {
            return order === ASC ? "ChevronUpIcon" : "ChevronDownIcon";
        }
    };

    function handleExtraAction(e: NcDropDownItem, qrOnboard: IQrOnboard) {
        if (e.id === 'edit') {
            navigate(`${qrOnboard.id}/edit`);
        }
        if (e.id === 'delete') {
            navigate(`${qrOnboard.id}/delete`);
        }
        if (e.id === 'notify') {
            navigate(`${qrOnboard.id}/notify`);
        }
        if (e.id === 'view') {
            navigate(`${qrOnboard.id}/view`);
        }
    }

    return (
        <div className="container px-4 mx-auto pt-10 pb-10">
            <div className="sm:flex sm:items-center sm:justify-between">
                <div>
                    <div className="flex items-center gap-x-3">
                        <h2 className="text-lg font-medium text-gray-800 dark:text-white"><Translate
                            contentKey={'onboard-management.title'}/></h2>
                    </div>
                </div>

                <div className="flex items-center mt-4 gap-x-3">
                    <Link
                        to="new"
                        className="flex items-center justify-center w-1/1 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round"
                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>

                        <span>
                            <Translate contentKey={'onboard-management.table.button.add'}/>
                        </span>
                    </Link>
                </div>
            </div>

            <div className="mt-6 md:flex md:items-center md:justify-between">
                <div
                    className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700">
                </div>

                <div className="relative flex items-center mt-4 md:mt-0">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                </svg>
            </span>

                    <input type="text"
                           onChange={(e) => {
                               setPagination({...pagination, query: e.target.value})
                           }}
                           value={pagination.query}
                           className={`block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg
                                    w-80 bg-gray-50 focus:border-blue-500 dark:bg-gray-700
                                    dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:border-grey-500
                                    ${loading ? 'disabled' : ''}`}
                           placeholder={translate('onboard-management.table.button.search')}/>
                </div>
            </div>

            <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                <thead className="bg-gray-50 dark:bg-gray-800">
                                <tr>
                                    <th scope="col"
                                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                        onClick={sort('email')}>
                                        <button className="flex items-center gap-x-3 focus:outline-none">
                                            <span><Translate contentKey={'onboard-management.table.header.email'}/></span>
                                            <DynamicHeroIcon icon={getSortIconByFieldName('email')}/>
                                        </button>
                                    </th>
                                    <th scope="col"
                                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                        onClick={sort('productName')}>
                                        <button className="flex items-center gap-x-3 focus:outline-none">
                                            <span><Translate contentKey={'onboard-management.table.header.productName'}/></span>
                                            <DynamicHeroIcon icon={getSortIconByFieldName('productName')}/>
                                        </button>
                                    </th>
                                    <th scope="col"
                                        className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                        onClick={sort('createdDate')}>
                                        <button className="flex items-center gap-x-3 focus:outline-none">
                                            <span><Translate contentKey={'onboard-management.table.header.createdDate'}/></span>
                                            <DynamicHeroIcon icon={getSortIconByFieldName('createdDate')}/>
                                        </button>
                                    </th>

                                    <th scope="col"
                                        className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                        onClick={sort('createdDate')}>
                                        <button className="flex items-center gap-x-3 focus:outline-none">
                                            <span><Translate contentKey={'onboard-management.table.header.activated'}/></span>
                                            <DynamicHeroIcon icon={getSortIconByFieldName('activated')}/>
                                        </button>
                                    </th>

                                    <th scope="col"
                                        className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                        onClick={sort('createdDate')}>
                                        <button className="flex items-center gap-x-3 focus:outline-none">
                                            <span><Translate contentKey={'onboard-management.table.header.provisioned'}/></span>
                                            <DynamicHeroIcon icon={getSortIconByFieldName('provisioned')}/>
                                        </button>
                                    </th>

                                    <th scope="col"
                                        className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    </th>

                                </tr>
                                </thead>
                                <tbody
                                    className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                {qrOnboards.map((qrOnboard: IQrOnboard, i: number) => (
                                    <tr key={qrOnboard.id}>
                                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                            <div>
                                                <p className="text-sm font-normal text-gray-600 dark:text-gray-400">{qrOnboard.email}</p>
                                            </div>
                                        </td>
                                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                            <div>
                                                <p className="text-sm font-normal text-gray-600 dark:text-gray-400">{qrOnboard.productName}</p>
                                            </div>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <div>
                                                <p className="text-gray-500 dark:text-gray-400">
                                                    <DateFormat
                                                        date={qrOnboard.createdDate ? qrOnboard.createdDate : undefined}/>
                                                </p>
                                            </div>
                                        </td>
                                        <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                            <div
                                                className={`${qrOnboard.activated ? 'text-emerald-500 bg-emerald-100/60' : 'text-rose-500 bg-rose-100/60'}  inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800`}>
                                                <Translate
                                                    contentKey={'onboard-management.table.activated.' + qrOnboard.activated}/>
                                            </div>
                                        </td>

                                        <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                            <div
                                                className={`${qrOnboard.provisioned ? 'text-emerald-500 bg-emerald-100/60' : 'text-rose-500 bg-rose-100/60'}  inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800`}>
                                                <Translate
                                                    contentKey={'onboard-management.table.provisioned.' + qrOnboard.provisioned}/>
                                            </div>
                                        </td>


                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <div
                                                className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:bg-gray-100">
                                                <NcDropDown data={[
                                                    {
                                                        id: "edit",
                                                        name: translate('onboard-management.table.button.edit'),
                                                        icon: "las la-pencil-alt",
                                                        hidden: qrOnboard.activated || qrOnboard.provisioned
                                                    },
                                                    {
                                                        id: "delete",
                                                        name: translate('onboard-management.table.button.delete'),
                                                        icon: "las la-trash-alt",
                                                        hidden: qrOnboard.activated || qrOnboard.provisioned

                                                    },
                                                    {
                                                        id: "notify",
                                                        name: translate('onboard-management.table.button.notify'),
                                                        icon: "las la-envelope",
                                                        hidden: qrOnboard.activated || qrOnboard.provisioned

                                                    },
                                                    {
                                                        id: "view",
                                                        name: translate('onboard-management.table.button.view'),
                                                        icon: "las la-eye",
                                                        hidden: false

                                                    },
                                                ]} onClick={(e) => {
                                                    handleExtraAction(e, qrOnboard);
                                                }}/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <Pagination
                activePage={pagination.activePage}
                onSelect={handlePagination}
                maxButtons={3}
                itemsPerPage={pagination.itemsPerPage}
                totalItems={totalItems}
            />
        </div>
    );
};

export default OnboardingManagement;
