import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";
import {IPlan} from "../model/plan.model";

export type TemplateState = Readonly<typeof templateInitialState>;

export const templateInitialState = {
    loading: false,
    templates: [] as any[],
};


export  const findTemplates = createAsyncThunk(
    'esim/find-templates',
   async => axios.get<IPlan[]>(`/api/product`),
    {serializeError: serializeAxiosError}
);

export const TemplateSlice = createSlice({
        name: 'templates',
        initialState: templateInitialState as TemplateState,
        reducers: {
            reset() {
                return templateInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(findTemplates.pending, (state) => {
                    state.loading = true;
                })
                .addCase(findTemplates.fulfilled, (state, action) => {
                    state.loading = false;
                    state.templates = action.payload.data;
                })
                .addCase(findTemplates.rejected, (state, action) => {
                    state.loading = false;
                })
            ;
        },
    })
;

export const {reset} = TemplateSlice.actions;

// Reducer
export default TemplateSlice.reducer;