import React, {FC, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {finish, init} from "../reducers/qr-onboard.reducer";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import {useNavigate} from "react-router";
import LoadingSpinner from "../LoadingSpinner";
import {getSession} from "../reducers/authentication";
import {IUser} from "../model/user.model";
import {IQrOnboard} from "../../shared/model/qr-onboard.model";
import {translate} from "react-jhipster";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";


const QuickOROnboarding: FC = () => {
    const {id} = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const loading: boolean = useAppSelector(state => state.qrOnboard.loading);
    const isError: boolean = useAppSelector(state => state.qrOnboard.isError);

    const qrOnboard: IQrOnboard = useAppSelector(state => state.qrOnboard.qrOnboard);
    const initialized = useRef(false)

    useEffect(() => {
        if (id) {
            if (!initialized.current) {
                initialized.current = true;
                dispatch(init(id));
            }
        } else {
            navigate('/');
        }
        if (qrOnboard.email) {
            dispatch(getSession());
        }
        if (isError) {
            navigate('/');
        }
    }, [qrOnboard, isError]);

    return (
        <div className={`container`}>
            <div className="container">
                <div className="mt-12 mb-12">
                    {
                        loading ? <LoadingSpinner/> :
                            <div>
                                <div className="container">
                                    <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                                        <div
                                            className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                                            <div
                                                className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                                <div className={`w-full`}>
                                                    <div
                                                        className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">
                                                        <div className="relative">
                                                            <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                                {translate("qr-page.title")}
                                                            </h2>
                                                            <span
                                                                className="block mt-6 text-slate-500 dark:text-slate-400">
                                            {translate("qr-page.text1", {email: qrOnboard.email})}
                                        </span>

                                                            <div className="flex space-x-2 mt-6 mb-6">
                                                                <ButtonPrimary
                                                                    onClick={() => {
                                                                        if (id != null) {
                                                                            dispatch(finish(id));
                                                                        }
                                                                        navigate('/account-plans');
                                                                    }}
                                                                    className="buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                                                >
                                                                    {translate("qr-page.button.activate")}
                                                                </ButtonPrimary>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default QuickOROnboarding;
