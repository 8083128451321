export enum IAction {
    RELOAD= "RELOAD"
}

export enum IEntity {
    SUBSCRIBER="SUBSCRIBER"
}

export interface IPrivateNotification {
    action:IAction;
    entity:IEntity
}