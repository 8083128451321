import {defaultAddressValue, IAddress} from "./address.model";

export interface IUser {
    id?: any;
    login?: string;
    name?: string;
    companyName?: string;
    email?: string;
    isSocialLogin?: boolean;
    activated?: boolean;
    langKey?: string;
    authorities?: string[];
    password?: string;
    address?: IAddress | null;
    privateNotificationChannel?: string;
    phoneNumber?: string;
    isTemporary?: boolean;
}

export function isAddressFulfilled(user: IUser): boolean {
    const address = user.address;
    if (!address || address === defaultAddressValue) {
        return false;
    }
    if(!address.address || !address.city || !address.country || !address.postalCode || !address.state){
        return false;
    }
    return true;
}

export const defaultValue: Readonly<IUser> = {
    id: '',
    login: '',
    name: '',
    email: '',
    activated: true,
    isSocialLogin: false,
    langKey: '',
    password: '',
    authorities: [],
    address: defaultAddressValue,
    phoneNumber: '',
};

