import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";
import {ISubscriber} from "../model/subscriber.model";

export type SubscriberState = Readonly<typeof subscriberInitialState>;

export const subscriberInitialState = {
    loading: false,
    subscribers: [] as ISubscriber[],
};


export const listSubscriber = createAsyncThunk(
    'subscriber/list',
    async () => axios.get<ISubscriber[]>(`/api/user/subscriber`, {
        headers: {
            'Cache-Control': 'no-cache'
        }
    }),
    {serializeError: serializeAxiosError}
);

export const updateSubscriber = createAsyncThunk(
    'subscriber/update',
    async (request: {
        activationCode: string,
        payload: { name: string }
    }, payload: any) => axios.put<ISubscriber>(`/api/user/sim/${request.activationCode}`, request.payload),
    {serializeError: serializeAxiosError}
);

export const subscriberSlice = createSlice({
        name: 'subscriber',
        initialState: subscriberInitialState as SubscriberState,
        reducers: {
            reset() {
                return subscriberInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(listSubscriber.pending, (state) => {
                    state.loading = true;
                })
                .addCase(listSubscriber.fulfilled, (state, action) => {
                    state.loading = false;
                    state.subscribers = action.payload.data;
                })
                .addCase(listSubscriber.rejected, (state, action) => {
                    state.loading = false;
                })
            ;
        },
    })
;

export const {reset} = subscriberSlice.actions;

// Reducer
export default subscriberSlice.reducer;
