import React, {FC, useEffect, useRef, useState} from "react";
import fileDownload from 'js-file-download'
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import ButtonSecondary from "../../shared/components/Button/ButtonSecondary";
import Prices from "../../shared/components/Prices/Prices";
import axios from "axios";
import {listSubscriber} from "../reducers/subscriber.reducer";
import {ISubscriber} from "../model/subscriber.model";
import {listOrders, reset} from "../reducers/order";
import LoadingSpinner from "../LoadingSpinner";
import PlanDetails from "../../shared/components/PlanDetails";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import {translate} from "react-jhipster";
import {keyGenerator} from "../config/utils";

const AccountOrder = () => {
    let dispatch = useAppDispatch();
    const orders = useAppSelector(state => state.order.orders);
    const loading = useAppSelector(state => state.order.loading);
    const initialized = useRef(false)


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            dispatch(reset());
            dispatch(listOrders());
            dispatch(listSubscriber());
        }
    }, [dispatch]);

    return (
        orders.length > 0 ?
            <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-6">
                <div>
                    {orders.map((order: any, index: number) => (
                        <Order key={index} invoice={order}/>
                    ))}
                </div>
            </div>
            :
            loading ?
                <LoadingSpinner/> :
                <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                    <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                        <div
                            className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                            <div className={`w-full`}>
                                <div
                                    className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                                    <div className="relative">

                                        <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                            {translate("global.destinations")}
                                        </h2>
                                        <span className="block mt-6 text-slate-500 dark:text-slate-400">
                                        {translate("global.empty-invoices")}
                                        </span>
                                        <div className="flex space-x-2 mt-6 mb-6">
                                            <ButtonPrimary
                                                href="/"
                                                className="buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white"
                                            >
                                                {translate("global.destination-button")}
                                            </ButtonPrimary>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

    );
};

interface IOrderProps {
    invoice: any
}

const Order: FC<IOrderProps> = ({invoice}) => {
    const loadingSubscribers = useAppSelector(state => state.subscriber.loading)
    const subscribers: ISubscriber[] = useAppSelector(state => state.subscriber.subscribers)
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const handleDownload = (invoiceId: string, filename: string) => {
        axios.get(`api/user/order/${invoiceId}/pdf`, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                setIsDownloading(false)
            })
    }

    function formatDate(date: Date) {
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const day = date.getDate();
        // const hours = date.getHours();
        // const minutes = date.getMinutes();
        return `${month}/${day}/${year}`;
    }

    const renderProductItem = (product: any, index: number, invoice: any) => {

        const getSubscriber = (activationCode: string): ISubscriber => {
            return subscribers.filter((s, i, a) => s.sim.activationCode === activationCode)[0];
        }

        return (
            <div key={keyGenerator()} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div className={"grid-cols-4 grid block "}>
                                <h3 className="col-span-3 text-base font-medium line-clamp-1">{
                                    invoice.metadata.PRODUCT_NAME !== undefined ? invoice.metadata.PRODUCT_NAME : ''
                                }</h3>
                                {invoice.metadata.PRODUCT_DESCRIPTION !== undefined ?
                                    <h4 className="col-span-3">
                                        {invoice.metadata.PRODUCT_DESCRIPTION}
                                    </h4> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    {/*console.log the invoice*/
    }
    // console.log(invoice);
    let simIds: string[] = [];
    if (invoice.metadata.SIM_CARD_ACTIVATION_CODES !== undefined) {
        simIds = invoice.metadata.SIM_CARD_ACTIVATION_CODES.split(',');
    }
    const getSubscriber = (activationCode: string): ISubscriber => {
        return subscribers.filter((s, i, a) => s.sim.activationCode === activationCode)[0];
    }
    return (
        <div key={keyGenerator()}
             className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 mb-12">
            <div className="p-6 flex gap-5 md:grid-cols-2 lg:grid-cols-2 xl:gap-8 bg-slate-50 dark:bg-slate-500/5">
                <div className="flex flex-col mr-auto   gap-3">
                    <p className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                        {invoice.number}

                    </p>
                    <p className="pl-3">
                        <span>{formatDate(new Date(invoice.statusTransitions.paidAt * 1000))}</span>
                        <span className="mx-2">·</span>
                        <span className="text-primary-500">{translate("invoice.status." + invoice.status)}</span>
                    </p>
                    <p className="text-gray-500 dark:text-slate-400 pl-3">
                        {simIds.map((simId, index) => {
                            let subscriber = getSubscriber(simId);
                            if (subscriber === undefined) {
                                return null;
                            }
                            return <span key={keyGenerator()} className="">{subscriber.imsiList[0].iccid}</span>;
                        })}
                    </p>
                </div>

                <div className="flex items-center  pr-3  gap-3">
                    {/* Download icon */}
                    <div
                        className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}
                        onClick={() => {
                            if (!isDownloading) {
                                setIsDownloading(true);
                                handleDownload(invoice.id, invoice.number + '.pdf');
                            }
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`w-6 h-6 ${isDownloading ? 'opacity-50' : 'hover:text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-200'}`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M12 8v8m0 0l-4-4m4 4l4-4"/>
                        </svg>
                    </div>
                    {/* Invoice price */}
                    <div
                        onClick={() => {
                            if (!isDownloading) {
                                setIsDownloading(true);
                                handleDownload(invoice.id, invoice.number + '.pdf');
                            }
                        }}
                        className={`w-10 h-10 sm:w-12 sm:h-12  focus:outline-none flex items-center justify-center`}
                    >
                        <Prices className="" price={invoice.total / 100} currency={invoice.currency}
                                vatIncluded={true}/>
                    </div>

                </div>

            </div>

            <div
                className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700 ">
                {invoice.lines.data.map((product: any, index: number) => renderProductItem(product, index, invoice))}
            </div>
        </div>
    );
}

export default AccountOrder;
