import React, {FC, ReactNode} from "react";
import ButtonPrimary from "../components/Button/ButtonPrimary";

export interface LegalHeroProps {
    className?: string;
    heading: ReactNode;
    subHeading: string;
    functionToCall: string
}

const LegalHero: FC<LegalHeroProps> = ({
                                           className = "",
                                           heading,
                                           subHeading,

                                           functionToCall,

                                       }) => {
    const hrefString = `javascript:${functionToCall}`;

    return (
        <div
            className={`nc-LegalHero relative ${className}`}
            data-nc-id="LegalHero"
        >
            <div
                className="flex flex-col  space-y-14  items-center relative text-center lg:text-left">
                <div className="w-screen max-w-full x space-y-5 ">
                    <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                        {heading}
                    </h2>
                    <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>

                </div>

            </div>
        </div>
    );
};

export default LegalHero;
