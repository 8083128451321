import React, {useEffect, useState} from "react";
import GitInfo from "react-git-info/macro";
import {useAppDispatch, useAppSelector} from "../../app/config/localStorage";
import {getVersion} from "../../app/reducers/info.reducer";
import {IUser} from "../../app/model/user.model";
import {AUTHORITIES} from "../../app/config/constants";
import DateFormat from "../utils/DateFormatUtils";

const Version = () => {
    const gitInfo = GitInfo();
    const dispatch = useAppDispatch();
    const version: any = useAppSelector(state => state.info.version);
    const versionLoading: any = useAppSelector(state => state.info.versionLoading);
    const account: IUser = useAppSelector(state => state.authentication.account);
    const [viewVersion, setViewVersion] = useState(account.authorities?.includes(AUTHORITIES.ADMIN))

    useEffect(() => {
        if (viewVersion){
        dispatch(getVersion());
        }
    }, []);
    return (
        <>
            {
                viewVersion ?
                    <div
                        className="fixed bottom-12 left-1/2 z-50 -translate-x-1/2 rounded-full bg-white dark:bg-gray-800 p-2 drop-shadow-2xl max-sm:w-11/12">
                        <div className="flex items-center justify-between gap-6 text-sm">
                            <div className="content-left pl-4 dark:text-white">
                                <p>FE: {gitInfo.branch} {gitInfo.commit.hash} {gitInfo.commit.shortHash} <DateFormat date={new Date(gitInfo.commit.date)}/> </p>
                                <p>BE: {version.branch} {version.commitId} {version.abbrevCommitId} <DateFormat date={new Date(version.commitTime)}/></p>
                            </div>
                            <div className="content-right text-end">
                                <button
                                    onClick={() => setViewVersion(false)}
                                    className={'bg-black text-white px-4 py-2 cursor-pointer rounded-full'}
                                >
                                    <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M6 18L18 6M6 6l12 12">
                                        </path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div> : null
            }
        </>
    )
}

export default Version;