import React, {useEffect, useState} from "react";
import {isEmail, translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {Link} from "react-router-dom";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import PasswordStrengthBar from "../shared/components/password/password-strength-bar";
import {handleRegister, reset} from "./reducers/register.reducer";
import Message from "../shared/components/Message";
import {Alert} from "../shared/components/Alert/Alert";

const PageSignUp = () => {
    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();
    const updatePassword = (event: any) => setPassword(event.target.value);

    const isLoading = useAppSelector(state => state.register.loading);
    const registrationSuccess = useAppSelector(state => state.register.registrationSuccess);
    const registrationFailure = useAppSelector(state => state.register.registrationFailure);
    const successMessage = useAppSelector(state => state.register.successMessage);
    useEffect(
        () => () => {
            dispatch(reset());
        },
        [dispatch],
    );
// @ts-ignore
    const handleValidSubmit = ({name, email, firstPassword}) => {
        const login = email;
        dispatch(handleRegister({email, login, name, password: firstPassword, langKey: 'en'}));
    };

    return (
        <div className={`nc-PageSignUp `} data-nc-id="PageSignUp">
            <div className="container mb-12">
                <div hidden={!registrationSuccess}>

                    <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 ">
                        <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0 ">
                            <div
                                className="flex flex-col  bg-slate-50 dark:bg-slate-500/5">
                                <div className={`w-full`}>
                                    <div
                                        className=" relative flex flex-col lg:flex-row  dark:bg-slate-800  p-6 lg:p-24">

                                        <div className="relative">

                                            <h2 className="font-semibold text-3xl mt-6 !leading-[1.13] tracking-tight">
                                                {translate("sign-up.step-2-title")}
                                            </h2>
                                            <span
                                                className="block mt-6 text-slate-500 dark:text-slate-400">
                                                {/*{successMessage}*/}
                                                {translate("sign-up.step-2-description")}
                                            </span>
                                            <div className="flex space-x-2 mt-6 mb-6">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div hidden={registrationSuccess}>
                    <h2 className="mt-12 mb-6 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        {translate("sign-up.title")}
                    </h2>
                    <div className="max-w-md mx-auto space-y-6">
                        {registrationFailure ?
                            <Alert type={'error'}>
                                {translate("sign-up.error.fail-register")}
                            </Alert>
                            : null}
                        <ValidatedForm className="grid grid-cols-1 gap-6" action="#" method="post"
                            // @ts-ignore
                                       onSubmit={handleValidSubmit}>
                            <ValidatedField
                                name="email"
                                label={translate("global.email-label")}
                                // @ts-ignore
                                placeholder={translate("global.email-placeholder")}
                                labelClass={'text-neutral-800 dark:text-neutral-200'}
                                inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                type="email"
                                validate={{
                                    required: {value: true, message: translate("global.error.email-required")},
                                    minLength: {
                                        value: 5,
                                        message: translate("global.error.email-length-min")
                                    },
                                    maxLength: {value: 254, message: translate("global.error.email-length-max")},
                                    validate: v => isEmail(v) || translate("global.error.email-invalid"),
                                }}
                                data-cy="email"
                            />
                            <ValidatedField
                                name="name"
                                label={translate("global.name-label")}
                                labelClass={'text-neutral-800 dark:text-neutral-200'}
                                inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                // @ts-ignore
                                placeholder={translate("global.name-placeholder")}
                                validate={{
                                    required: {value: true, message: translate("global.error.name-required")},
                                    minLength: {value: 1, message: translate("global.error.name-length-min")},
                                    maxLength: {value: 254, message: translate("global.error.name-length-max")},
                                }}
                                data-cy="username"
                            />
                            <ValidatedField
                                name="firstPassword"
                                label={translate("global.password-label")}
                                // @ts-ignore
                                placeholder={translate("global.password-placeholder")}
                                labelClass={'text-neutral-800 dark:text-neutral-200'}
                                inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                type="password"
                                onChange={updatePassword}
                                validate={{
                                    required: {value: true, message: translate("global.error.password-required")},
                                    minLength: {
                                        value: 4,
                                        message: translate("global.error.password-length-min")
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: translate("global.error.password-length-max")
                                    },
                                }}
                                data-cy="firstPassword"
                            />
                            <ValidatedField
                                name="secondPassword"
                                label={translate("sign-up.confirm-password-label")}
                                // @ts-ignore
                                placeholder={translate("sign-up.confirm-password-placeholder")}
                                labelClass={'text-neutral-800 dark:text-neutral-200'}
                                inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                type="password"
                                validate={{
                                    required: {value: true, message: translate("global.error.password-confirm")},
                                    minLength: {
                                        value: 4,
                                        message: translate("global.error.password-length-min")
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: translate("global.error.password-length-max")
                                    },
                                    validate: v => v === password || translate("global.error.password-mismatch"),
                                }}
                                data-cy="secondPassword"
                            />
                            <PasswordStrengthBar password={password}/>

                            <ButtonPrimary
                                className={'buyCardButton w-full bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white'}
                                type="submit" disabled={isLoading}>{translate("sign-up.submit-button")}</ButtonPrimary>
                        </ValidatedForm>

                        <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        {translate("sign-up.account-exists")} <Link className="text-green-600"
                                                                    to="/login">{translate("global.menu.sign-in")}</Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageSignUp;
