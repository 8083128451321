export interface ICoupon {
    id: string;
    name: string;
    amountOff?: number;
    percentOff?: number;
    products: string[];
}

export const defaultCouponValue: Readonly<ICoupon> = {
    id : '',
    name: '',
    amountOff: 0,
    percentOff: 0,
    products: []
};
