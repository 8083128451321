import React from "react";
import {useAppState} from "../../app/config/AppStateContext";


export interface ILogoProps {
    img?: string;
    imgLight?: string;
    className?: string;
}

const Logo = ({
                  img = "logoImg",
                  imgLight = "logoImg",
                  className = "flex-shrink-0",
              }) => {
    const {state} = useAppState();

    return (
        <div
            // href="/"
            className={`ttnc-logo inline-block text-slate-600 ${className}`}
        >
            {/* THIS USE FOR MY CLIENT */}
            {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
            {img ? (
                <a href={state.tenant.url === "portal.whoopgo.com" ? "https://whoopgo.com" : "/"}>
                    <img
                        className={`logo block lg:h-14 sm:h-10 w-auto ${
                            imgLight ? "dark:hidden" : ""
                        }`}
                        src={state.tenant.logoUrl}
                        alt="Logo"
                        sizes="200px"
                    />
                </a>
            ) : (
                "Logo Here"
            )}
            {imgLight && (
                <a href={state.tenant.url === "portal.whoopgo.com" ? "https://whoopgo.com" : "/"}>

                    <img
                        className="logo hidden h-14 sm:h-14 w-auto dark:block"
                        src={state.tenant.logoUrl}
                        alt="Logo-Light"
                        sizes="200px"
                    />
                </a>
            )}
        </div>
    );
};

export default Logo;
