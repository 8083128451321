import React, { useEffect } from 'react';

interface GoogleAnalyticsProps {
    trackingId: string;
}

const GoogleAnalytics: React.FC<GoogleAnalyticsProps> = ({ trackingId }) => {


    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        document.head.appendChild(script);

        script.onload = () => {
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                // @ts-ignore
                window.dataLayer.push(arguments);
            }
            // @ts-ignore
            gtag('js', new Date());
            // @ts-ignore
            gtag('config', trackingId);
        };

        return () => {
            // Cleanup script tag if component unmounts
            document.head.removeChild(script);
        };
    }, [trackingId]);

    return <></>; // or return null if you prefer
};

export default GoogleAnalytics;
