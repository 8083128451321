import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import {defaultPlanValue, IPlan} from "../model/plan.model";
import {useLocation, useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import OrderInfo from "./OrderInfo";
import PaymentMethod from "./PaymentMethod";
import AccountBillingInformation from "../account/AccountBillingInformation";
import {isAddressFulfilled} from "../model/user.model";
import {findTemplates} from "../reducers/template";
import {useAppState} from "../config/AppStateContext";
import V2PaymentMethod from "./V2PaymentMethod";


export interface IPaymentIntent {
    productId: string;
    quantity: number;
    subscriber: string;
    coupon?: string;
    cancelEndpoint?: string;
    email?: string;
}

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const account = useAppSelector(state => state.authentication.account);
    const [showPayment, setShowPayment] = useState(false)
    const [paymentIntent, setPaymentIntent] = useState<IPaymentIntent>();
    const initialized = useRef(false)
    const {id} = useParams();
    const templates: IPlan[] = useAppSelector(state => state.template.templates);
    let plan: IPlan = defaultPlanValue;
    const {state} = useAppState();
    let v2Payment: boolean = !state.tenant.paymentIntent;
    console.log('v2Payment', v2Payment);
    console.log('state.tenant.guestCheckout', state.tenant.guestCheckout);
    useEffect(() => {
        console.log('checkout useEffect');
        if (!initialized.current) {
            initialized.current = true;

            if (templates.length === 0) {
                dispatch(findTemplates());
            }


            if (!isAuthenticated && state.tenant.guestCheckout === false) {
                navigate('/login', {state: {from: location.pathname}});
                return;
            }

            if (plan === defaultPlanValue) {
                navigate('/');
                return;
            } else {
                navigate(location.pathname);
            }
        }

    }, [navigate, isAuthenticated, location, plan, account, templates, dispatch]);

    plan = templates.filter((p, i, a) => p.id === id)[0];

    if (plan === defaultPlanValue) {
        navigate('/');
        return;
    }

    const updateOrderDetails = (paymentIntent: IPaymentIntent, showPayment: boolean) => {
        setPaymentIntent(paymentIntent);
        setShowPayment(showPayment);
        console.log('updateOrderDetails', paymentIntent, showPayment);

    }

    // @ts-ignore
    return (
        <div className={`nc-AccountPage container`}>
            <div className="nc-AccountCommonLayout container">
                <div className="mt-12 mb-12">
                    {plan !== defaultPlanValue && plan !== undefined ?
                        <div className="flex-grow  space-y-6">
                            <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                                <h2 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                    {plan.name}
                                </h2>
                            </div>
                            {!showPayment ?
                                <>
                                    {!isAddressFulfilled(account) && state.tenant.addressValidation ?
                                        <AccountBillingInformation/> :
                                        <OrderInfo
                                            //@ts-ignore
                                            isActive={true} plan={plan} callback={updateOrderDetails}/>
                                    }

                                </>
                                : <>{
                                    v2Payment ?
                                        <V2PaymentMethod
                                            //@ts-ignore
                                            iPaymentIntent={paymentIntent}/>
                                        :
                                        <PaymentMethod
                                            //@ts-ignore
                                            callback={updateOrderDetails}
                                            //@ts-ignore
                                            iPaymentIntent={paymentIntent}
                                        />
                                }</>
                            }
                        </div> : null}
                </div>
            </div>
        </div>
    );
};

export default Checkout;
