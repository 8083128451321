import axios from 'axios';
import {Storage} from 'react-jhipster';
import {AUTH_TOKEN_KEY, clearImpersonateAuthToken, IMPERSONATE_AUTH_TOKEN_KEY} from "../reducers/authentication";

const TIMEOUT = 60 * 1000;


export const BASE_URL = '/';
//export const BASE_URL = 'http://localhost:8081/';
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = BASE_URL;
//get header called X-TenantID from request

export const isImpersonate = () => {
    const impersonateToken = Storage.local.get(IMPERSONATE_AUTH_TOKEN_KEY) || Storage.local.get(IMPERSONATE_AUTH_TOKEN_KEY);
    return !!impersonateToken;
}

// @ts-ignore
const setupAxiosInterceptors = onUnauthenticated => {
    // @ts-ignore
    const onRequestSuccess = config => {
        const token = Storage.local.get(AUTH_TOKEN_KEY) || Storage.local.get(AUTH_TOKEN_KEY);

        const impersonateToken = Storage.local.get(IMPERSONATE_AUTH_TOKEN_KEY) || Storage.local.get(IMPERSONATE_AUTH_TOKEN_KEY);

        if (impersonateToken) {
            config.headers.Authorization = `Bearer ${impersonateToken}`;
            return config;
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };
    // @ts-ignore
    const onResponseSuccess = response => response;
    // @ts-ignore
    const onResponseError = err => {
        const status = err.status || (err.response ? err.response.status : 0);
        if (status === 403 || status === 401) {

            if (status === 401) {
                clearImpersonateAuthToken();
            }

            onUnauthenticated();
        }
        return Promise.reject(err);
    };
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
