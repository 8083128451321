import {translate, ValidatedField, ValidatedForm} from "react-jhipster";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import {updateUser} from "../reducers/authentication";
import {ICountry} from "../model/country";
import {IUser} from "../model/user.model";
import {useAppState} from "../config/AppStateContext";

const AccountBillingInformation = () => {
    const account: IUser = useAppSelector(state => state.authentication.account);
    const loading = useAppSelector(state => state.authentication.loading);
    const countries: ICountry[] = useAppSelector(state => state.country.countries);
    const dispatch = useAppDispatch();
    const initialized = useRef(false)
    const [companyName, setCompanyName] = useState<string>('');
    const {state} = useAppState();

    const {
        handleSubmit,
        register,
        reset: resetForm,
        formState: {errors},
    } = useForm({mode: 'onTouched', defaultValues: account});

    useEffect(() => {
        resetForm(account);
        if (!initialized.current) {
            initialized.current = true;
        }
        if (account.companyName) {
            setCompanyName(account.companyName);
        }
    }, [account, dispatch, resetForm]);
    const liveChatEnabled = state.tenant && state.tenant.liveChatEnabled !== undefined ? state.tenant.liveChatEnabled : true;
    const vatMandatory = state.tenant && state.tenant.vatMandatory !== undefined ? state.tenant.vatMandatory : false;
    const companyMandatory = state.tenant && state.tenant.companyMandatory !== undefined ? state.tenant.companyMandatory : false;

    const updateAccount = (values: any) => {
        dispatch(updateUser(values));
    };

    return (
        <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"><h2
            className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">{translate("billing-details.title")}</h2>

            <ValidatedForm
                //@ts-ignore
                onSubmit={handleSubmit(updateAccount)}>
                <div className={`grid ${companyName ? 'lg:grid-cols-3' : 'lg:grid-cols-2'} gap-5 xl:gap-8`}>
                    <div className="relative px-3 py-4">
                        <ValidatedField
                            register={register}
                            name="name"
                            label={translate("global.name-label")}
                            labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                            placeholder={translate("global.name-placeholder")}
                            type="text"
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            validate={{
                                deps: ['name'],
                                required: {value: true, message: translate("global.error.name-required")},
                                minLength: {
                                    value: 1,
                                    message: translate("global.error.name-length-min")
                                },
                                maxLength: {
                                    value: 255,
                                    message: translate("global.error.name-length-max")
                                },
                            }}
                            data-cy="name"
                        />
                    </div>
                    <div className="relative px-3 py-4">
                        <ValidatedField
                            register={register}
                            onChange={(e) => setCompanyName(e.target.value)}
                            name="companyName"
                            label={translate("global.company-label")}
                            labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                            placeholder={translate("global.company-placeholder")}
                            type="text"
                            error={errors?.companyName}
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            data-cy="companyName"
                            validate={{
                                validate: value => {
                                    if (companyMandatory) {
                                        return value ? true : translate("global.company-label");
                                    }
                                    return true;
                                }
                            }}
                        />
                    </div>
                    <div className={`relative px-3 py-4 ${companyName ? '' : 'hidden'}`}>
                        <ValidatedField
                            register={register}
                            name="vatNumber"
                            label={translate("global.vat-number")}
                            labelClass={`text-base font-medium text-neutral-900 dark:text-neutral-200`}
                            placeholder={translate("global.vat-number")}
                            type="text"
                            //@ts-ignore
                            error={errors?.vatNumber}
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            data-cy="vatNumber"
                            validate={{
                                validate: value => {
                                    if (companyName.length && vatMandatory) {
                                        return value ? true : translate("global.error.vat-number-required");
                                    }
                                    return true;
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="grid lg:grid-cols-2 gap-5 xl:gap-8">
                    <div className="relative px-3 py-4">
                        {countries ?
                            <ValidatedField
                                type="select"
                                register={register}
                                //@ts-ignore
                                error={errors?.address?.country}
                                data-cy="address.country"
                                label={translate("global.country-label")}
                                labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                                placeholder={translate("global.country-placeholder")}
                                inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                name="address.country"
                                validate={{
                                    value: {value: true, message: translate("global.error.country-required")},
                                    required: {value: true, message: translate("global.error.country-required")},
                                }}>
                                <option></option>
                                {countries.map(c => (
                                    <option value={c.countryIso2} key={c.countryIso2}>
                                        {translate(`global.countries.${c.countryIso2}`)}
                                    </option>
                                ))}
                            </ValidatedField>
                            : null}
                    </div>

                    <div className="relative px-3 py-4">
                        <ValidatedField
                            register={register}
                            //@ts-ignore
                            error={errors?.address?.city}
                            name="address.city"
                            type="text"
                            label={translate("global.city-label")}
                            labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                            placeholder={translate("global.city-placeholder")}
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            data-cy="address.city"
                            validate={{
                                required: {
                                    value: true,
                                    message: translate("global.error.city-required")
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="grid lg:grid-cols-2 gap-5 xl:gap-8">
                    <div className="relative px-3 py-4">
                        <ValidatedField
                            register={register}
                            //@ts-ignore
                            error={errors?.address?.state}
                            name="address.state"
                            type="text"
                            label={translate("global.state-label")}
                            labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                            placeholder={translate("global.state-placeholder")}
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            data-cy="address.state"
                            validate={{
                                required: {
                                    value: true,
                                    message: translate("global.error.state-required")
                                },
                            }}
                        />
                    </div>
                    <div className="relative px-3 py-4">
                        <ValidatedField
                            register={register}
                            //@ts-ignore
                            error={errors?.address?.postalCode}
                            name="address.postalCode"
                            type="text"
                            label={translate("global.postal-code-label")}
                            labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                            placeholder={translate("global.postal-code-placeholder")}
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            data-cy="address.postalCode"
                            validate={{
                                required: {
                                    value: true,
                                    message: translate("global.error.postal-code-required")
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="grid lg:grid-cols-1 gap-5 xl:gap-8">
                    <div className="relative px-3 py-4">
                        <ValidatedField
                            //@ts-ignore
                            error={errors?.address?.address}
                            register={register}
                            name="address.address"
                            type="text"
                            label={translate("global.address-label")}
                            labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                            placeholder={translate("global.address-placeholder")}
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            data-cy="address.address"
                            validate={{
                                required: {
                                    value: true,
                                    message: translate("global.error.address-required")
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="grid lg:grid-cols-1 gap-5 xl:gap-8">
                    <div className="relative px-3 py-4">
                        <ValidatedField
                            //@ts-ignore
                            error={errors?.phoneNumber}
                            register={register}
                            name="phoneNumber"
                            type="text"
                            label={translate("global.phoneNumber-label")}
                            labelClass={'text-base font-medium text-neutral-900 dark:text-neutral-200'}
                            placeholder={translate("global.phoneNumber-placeholder")}
                            inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                            data-cy="phoneNumber"
                            validate={{
                                pattern: {
                                    value: /^[\+\(\s.\-\/\d\)]{5,30}$/,
                                    message: translate("global.error.phoneNumber-invalid")
                                }
                            }}
                        />
                    </div>
                </div>

                <ButtonPrimary
                    className={'buyCardButton mt-3 bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white'}
                    type="submit"
                    disabled={loading}>{translate("billing-details.submit-button")}</ButtonPrimary>
            </ValidatedForm>
        </div>
    )
}
export default AccountBillingInformation;
