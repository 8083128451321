import React, {useEffect} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {tokenLogin} from "./reducers/authentication";

const QuickLogin: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.authentication); // Select authentication state
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get("token");
        const redirectToken = params.get("redirectToken");

        if (!token && !redirectToken) {
            navigate("/");
            return;
        }
        if (token) {
            dispatch(tokenLogin(token));
        }

        if (redirectToken) {
            dispatch(tokenLogin(redirectToken));
        }
    }, [location.search, dispatch]);

    // Redirect after successful login
    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate("/account");
        }
    }, [auth.isAuthenticated, navigate]);



    return null;
};

export default QuickLogin;
