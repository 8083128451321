import {IAddress} from "../../app/model/address.model";

export enum IQROnboardType {
    FULL= "FULL"
}

export interface IQrOnboard {
    id: string
    email?: string
    name?: string
    productId: string
    productName: string
    promoCode: string
    activated: boolean
    provisioned: boolean
    type: IQROnboardType
    address?: IAddress | null;
    phoneNumber: string,
    createdDate?: Date
    activatedDate?: Date
    provisionedDate?: Date
}

export const defaultValue: Readonly<IQrOnboard> = {
    id: '',
    email: '',
    name: '',
    productId: '',
    productName: '',
    promoCode: '',
    activated: false,
    provisioned: false,
    type: IQROnboardType.FULL,
    address: null,
    phoneNumber: '',
};